import { React, useState } from "react";
import {
  XImg,
  YourHelthBg,
  HelthboxTiles,
  YourHelthImg,
  YourHelthImg3,
  YourHelthImg2,
  HelthboxHeading,
  HelthboxPara,
  HelthLink,
  HealthContainer,
  HealthImgContainer,
  HealthBox,
} from "./HealthBlockStyle";
import { Link } from "react-router-dom";
import { ToolModal } from "../../component/ToolModal/ToolModal";
import { RightOutlined } from "@ant-design/icons";
import {
  ArrowIcon,
  BookmarkIcon,
  CalenderIcon,
  DoctorIcon,
  ReportIcon,
  ScopeIcon,
} from "../../component/CustomIcons";

function HealthBlock() {
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const showModal2 = () => {
    setIsModalVisible2(true);
  };

  return (
    <>
      <YourHelthBg>
        <YourHelthImg
          className="hideMobile health-img-animation"
          src="./images/svg/mask-6.svg"
          alt="no"
        />
        <div className="container yourHelthWrap">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h1 className="heading-home ">Your Health</h1>
            </div>
          </div>
        </div>
      </YourHelthBg>
      <YourHelthBg>
        {/* <YourHelthImg2 className=" hideMobile" src="./images/svg/home-helth-bg.svg" alt="no" /> */}
        <div className="container-fluid p-0">
          <HealthBox className="row align-items-center">
            <div className="col-12 col-sm-12 col-md-6">
              <XImg
                className="img-fluid img-animation"
                src="./images/family.svg"
                alt="no"
              />
            </div>
            <div className="col-12 col-sm-12 col-md-5">
              {/* requested by lilac */}
              {/* <HelthboxTiles
                className=""
                onClick={showModal2}
                style={{ cursor: "pointer" }}
              >
                <HealthImgContainer>
                  <CalenderIcon size={60} />
                  <HealthContainer>
                    <HelthboxHeading className=" text-left">
                      Tools
                    </HelthboxHeading>
                    <HelthboxPara className=" text-left">
                      Your Tools
                    </HelthboxPara>
                  </HealthContainer>
                </HealthImgContainer>
                <ArrowIcon size={32} />
              </HelthboxTiles> */}

              <ToolModal
                visible={isModalVisible2}
                onClose={() => setIsModalVisible2(false)}
              />
              <HelthLink to="/consultation">
                <HelthboxTiles style={{ cursor: "pointer" }}>
                  <HealthImgContainer>
                    <DoctorIcon size={60} />
                    <HealthContainer>
                      <HelthboxHeading className=" text-left">
                        Consult An Expert
                      </HelthboxHeading>
                      <HelthboxPara className=" text-left">
                        1-1 Conversation
                      </HelthboxPara>
                    </HealthContainer>
                  </HealthImgContainer>
                  <ArrowIcon size={32} />
                </HelthboxTiles>
              </HelthLink>
              {/* requested by lilac */}
              {/* <HelthLink to="/book-test">
                <HelthboxTiles style={{ cursor: "pointer" }}>
                  <HealthImgContainer>
                    <ScopeIcon size={60} />
                    <HealthContainer>
                      <HelthboxHeading className=" text-left">
                        Book Test
                      </HelthboxHeading>
                      <HelthboxPara className=" text-left">
                        Book a Test
                      </HelthboxPara>
                    </HealthContainer>
                  </HealthImgContainer>
                  <ArrowIcon size={32} />
                </HelthboxTiles>
              </HelthLink> */}
              <HelthLink to="/my-reports">
                <HelthboxTiles style={{ cursor: "pointer" }}>
                  <HealthImgContainer>
                    <ReportIcon size={60} />
                    <HealthContainer>
                      <HelthboxHeading className=" text-left">
                        My Reports
                      </HelthboxHeading>
                      <HelthboxPara className=" text-left">
                        My Reports
                      </HelthboxPara>
                    </HealthContainer>
                  </HealthImgContainer>
                  <ArrowIcon size={32} />
                </HelthboxTiles>
              </HelthLink>
              {/* requested by lilac */}
              {/* <HelthLink to="/bookmark-articles">
                <HelthboxTiles style={{ cursor: "pointer" }}>
                  <HealthImgContainer>
                    <BookmarkIcon size={60} />
                    <HealthContainer>
                      <HelthboxHeading className=" text-left">
                        My Bookmarks
                      </HelthboxHeading>
                      <HelthboxPara className=" text-left">
                        Bookmarked Articles
                      </HelthboxPara>
                    </HealthContainer>
                  </HealthImgContainer>
                  <ArrowIcon size={32} />
                </HelthboxTiles>
              </HelthLink> */}
            </div>
          </HealthBox>
        </div>
      </YourHelthBg>
    </>
  );
}

export default HealthBlock;
