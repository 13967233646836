import React, { useState, useEffect } from "react";
import {
  Skeleton,
  Row,
  Col,
  Form,
  Typography,
  Space,
  Rate,
  Input,
  Button,
} from "antd";
import { connect } from "react-redux";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { JitsiMeet } from "./AppointmentMeetingStyle.js";
import config from "../../config";
import _ from "lodash";
import moment from "moment";
import { useHistory, Link } from "react-router-dom";
import {
  DrDetailsRightHead,
  TopContainer,
  PageWrap,
} from "../BookingDetails/BookingDetailsStyle";
import {
  getAppointmentDetails,
  getAppointmentDetailsReset,
} from "../../action/getAppointmentDetailsAction";
import {
  saveAppointmentFeedback,
  saveAppointmentFeedbackReset,
} from "../../action/saveAppointmentFeedbackAction";
import { convertTZ, dateTimeUTCFormat, timeSlotFormat } from "../../utils";
import {
  bookedAppointmentActivity,
  bookedAppointmentReset,
} from "../../action/bookingAppointmentActivity";
import { StarFilled } from "@ant-design/icons";
import { createGlobalStyle } from "styled-components";

const { Title } = Typography;

const AppointmentMeeting = (props) => {
  /* variable */
  const {
    getAppointmentDetails,
    getAppointmentDetailsReset,
    getAppointmentDetailsState,
    bookedAppointmentActivity,
    bookingAppointmentActivityState,
    bookedAppointmentReset,
    saveAppointmentFeedback,
    saveAppointmentFeedbackReset,
    saveAppointmentFeedbackState,
  } = props;

  const id = props.match.params.id;
  const [onCall, setOnCall] = useState(false);
  const [refresh, setRefresh] = useState(0);
  // const [form] = Form.useForm();
  // const [showFeedback, setShowFeedback] = useState(false)

  /* callbacks */
  useEffect(() => {
    return () => {
      getAppointmentDetailsReset();
    };
  }, []);
  useEffect(() => {
    if (bookingAppointmentActivityState.apiState) {
      bookedAppointmentReset();
    }
  }, [bookingAppointmentActivityState]);

  let history = useHistory();

  useEffect(() => {
    if (getAppointmentDetailsState.apiState === "success") {
      let date = moment(getAppointmentDetailsState.data.date).utc().unix();
      let to_date = moment(getAppointmentDetailsState.data.to_date)
        .utc()
        .unix();
      // let current_date = moment(new Date()).add(-2, 'minute').unix()
      let current_date = convertTZ(
        moment().add(-10, "second").format("YYYY-MM-DD HH:mm:ss") + " +0000",
        "Asia/Kolkata"
      );
      current_date = moment(current_date).utc().unix();
      if (date <= current_date && to_date >= current_date) {
        setOnCall(true);
        bookedAppointmentActivity({
          book_appointment_id: id,
          activity_type: "joined",
        });
      } else {
        setInterval(() => setRefresh(refresh + 1), 5000);
      }
    }
  }, [getAppointmentDetailsState, refresh]);

  useEffect(() => {
    if (id) {
      getAppointmentDetails({
        id: id,
      });
    }
  }, [id]);

  // function onFinish(values)
  // {
  //     const data = {
  //         "rating": values.rating,
  //         "comment": values.comment,
  //         "doctor_id": getAppointmentDetailsState.data.doctor_id._id,
  //         "book_appointment_id": getAppointmentDetailsState.data._id,
  //     }

  //     saveAppointmentFeedback(data)
  // }

  // function onFinishFailed(errorInfo)
  // {
  //     console.log("Failed=>",errorInfo)
  // }
  return (
    <>
      <div className="container" style={{ marginTop: "10%", marginBottom: "5%" }}>
        <TopContainer>
          <img
            className="align-center mobile-height"
            src="./images/lilac-insights-logo.svg"
            alt="exp"
          />
          {/* <Title level={1}>Consult</Title> */}
        </TopContainer>

        <Row gutter={22}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            {getAppointmentDetailsState.apiState === "loading" && (
              <Skeleton active />
            )}

            {getAppointmentDetailsState.apiState === "error" && (
              <p>{getAppointmentDetailsState.message}</p>
            )}
            {getAppointmentDetailsState.apiState === "success" && (
              <>
                {!onCall &&
                  !getAppointmentDetailsState.data.is_can_join_room && (
                    <Row gutter={20}>
                      <Col span="12">
                        {" "}
                        Your session will start on{" "}
                        {dateTimeUTCFormat(
                          getAppointmentDetailsState.data.date
                        )}
                      </Col>
                    </Row>
                  )}
                {!onCall &&
                  !getAppointmentDetailsState.data.is_can_join_room &&
                  getAppointmentDetailsState.data
                    .is_appointment_time_passed && (
                    <Row gutter={20}>
                      <Col span="12"> The session time has ended</Col>
                    </Row>
                  )}
                {onCall && getAppointmentDetailsState.data.is_can_join_room && (
                  <JitsiMeet>
                    <div class="jitsi-wrap">
                      <JitsiMeeting
                        domain={config.jitsi_meet_domian}
                        roomName={`${getAppointmentDetailsState.data.room_id}`}
                        userInfo={{
                          displayName: `${getAppointmentDetailsState.data.patient_id.name.firstName} ${getAppointmentDetailsState.data.patient_id.name.lastName}`,
                        }}
                        configOverwrite={{
                          prejoinPageEnabled: false,
                          enableClosePage: false,
                          enableCalendarIntegration: false,
                          disablePolls: true,
                          p2p: {
                            enabled: true,
                          },
                          startWithVideoMuted: true,
                          startWithAudioMuted: true,
                          welcomePage: {
                            disabled: true,
                          },
                          toolbarButtons: [
                            "camera",
                            "hangup",
                            "chat",
                            "microphone",
                          ],
                          disableDeepLinking: true,
                        }}
                        interfaceConfigOverwrite={{
                          MOBILE_APP_PROMO: false,
                          SHOW_BRAND_WATERMARK: false,
                          SHOW_CHROME_EXTENSION_BANNER: false,
                          SHOW_JITSI_WATERMARK: false,
                          SHOW_POWERED_BY: false,
                          SHOW_PROMOTIONAL_CLOSE_PAGE: false,
                        }}
                        onReadyToClose={() => {
                          bookedAppointmentActivity({
                            book_appointment_id: id,
                            activity_type: "disconnected",
                          });
                          history.push({
                            pathname: "/appointment-details",
                            state: {
                              Details: {
                                item: getAppointmentDetailsState.data,
                              },
                              isBooking: true,
                              doctor: `${getAppointmentDetailsState.data.doctor_id._id}`,
                              app: "upcom",
                            },
                          });
                        }}
                        loadingComponent={<p> Your meeting is loading ...</p>}
                        subject={`Time Slot: ${timeSlotFormat(
                          getAppointmentDetailsState.data.time_slot_start
                        )} - ${timeSlotFormat(
                          getAppointmentDetailsState.data.time_slot_end
                        )}`}
                      />
                    </div>
                  </JitsiMeet>
                )}
                {/* {
                                    !onCall && showFeedback &&
                                    <Form
                                        xs={22} sm={22} md={12} lg={12} xl={12}
                                        form={form}
                                        initialValues={{ remember: true }}
                                        layout="vertical"
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                    >
                                        <Row span={22} style={{marginBottom: "0.7em"}}>
                                            <Space direction="vertical">
                                                <Title style={{margin: "0px"}}>Feedback</Title>
                                                <Title level={5}>Your feedback is valuable to us</Title>
                                            </Space>
                                        </Row>

                                        <Form.Item 
                                            name="rating" 
                                            label="Rate your experience"
                                            rules={[
                                                {
                                                    required:true, 
                                                    message:"Please select rating"
                                                }
                                            ]}
                                        >
                                            <Rate></Rate>
                                        </Form.Item>
                                        <Form.Item name="comment" label="Write your feedback here">
                                            <Input.TextArea cols={5} maxLength={500}/>
                                        </Form.Item>

                                        <Space>
                                            <Button className="themeBtn w-10" size="large" htmlType="submit">
                                                Submit
                                            </Button>
                                            <Link to={{
                                                pathname: "/appointment-details",
                                                state: {
                                                    Details: {"item": getAppointmentDetailsState.data},
                                                    isBooking: false,
                                                    doctor: `${getAppointmentDetailsState.data.doctor_id._id}`,
                                                    app:'past',
                                                },
                                            }}>
                                                <Button>
                                                    View Appointment
                                                </Button>
                                            </Link>    
                                        </Space>   

                                        

                                    </Form>
                                } */}
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  getAppointmentDetailsState: state.getAppointmentDetails,
  saveAppointmentFeedbackState: state.saveAppointmentFeedback,
  bookingAppointmentActivityState: state.bookingAppointmentActivityState,
});
const mapDispatchToProps = (dispatch) => ({
  getAppointmentDetails: (params) => dispatch(getAppointmentDetails(params)),
  getAppointmentDetailsReset: () => dispatch(getAppointmentDetailsReset()),
  bookedAppointmentActivity: (params) =>
    dispatch(bookedAppointmentActivity(params)),
  bookedAppointmentReset: () => dispatch(bookedAppointmentReset()),
  saveAppointmentFeedback: (params) =>
    dispatch(saveAppointmentFeedback(params)),
  saveAppointmentFeedbackReset: () => dispatch(saveAppointmentFeedbackReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentMeeting);
