import { React, useEffect, useState } from "react";
import { Typography, Radio, Button, Modal, Skeleton } from "antd";
import {
  getDoctorDetail,
  getDoctorDetailReset,
} from "../../action/getDoctorDetailAction";
import {
  getScheduleTimingList,
  getScheduleTimingListReset,
} from "../../action/getScheduleTimeListAction";
import { connect } from "react-redux";
import { timeSlotFormat } from "../../utils";
import { useLocation, Link } from "react-router-dom";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { DoctorDetailBlock } from "../../component/DoctorDetailBlock/DoctorDetailBlock";
import { savePaymentData } from "../../action/savePaymentDataAction";
import moment, { unix } from "moment";
import config from "../../config";
import { getPatientToken } from "../../utils";

//STYLE
import "./DoctorAppoint.css";
import {
  PageWrap,
  TopContainer,
  SlotRadioButton,
  DocDetailsHeading2,
  TimeRadioButton,
} from "./DoctorAppointStyle";
import axios from "axios";
import ScrollToTop from "../../component/ScrollToTop/ScrollToTop";

function DoctorAppoint(props) {
  const {
    getDoctorDetail,
    getDoctorDetailReset,
    getDoctorDetailState,
    getScheduleTimingListState,
    getScheduleTimingList,
    getScheduleTimingListReset,
    savePaymentData,
    savePaymentDataState,
  } = props;
  const [doctorData, setDoctorData] = useState({
    id: "",
    speciality: "",
    fname: "",
    lname: "",
    gender: "",
    qualification: "",
    profile_picture: "",
    description: "",
    tier_1_fee: "",
    short_description: "",
    tier_2_fee: "",
    exprience: "",
    salutation: "",
  });
  const [value, setValue] = useState();
  const [timeValue, setTimeValue] = useState();
  const [endTime, setEndTime] = useState();
  const [sendDate, setSendDate] = useState();
  const [CurrSlot, setCurrSlot] = useState();
  const { Text, Title } = Typography;
  const [TimeSlots, setTimeSlots] = useState([]);
  const location = useLocation();
  const doctor = location.state.doctor;
  const type = location.state.type;
  const doctorParams = {
    doctor: doctor,
  };
  const [selected, setSelected] = useState(true);
  const [stime, setstime] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [numbers, SetNumbers] = useState([
    {
      day: " ",
      date: "",
      slots: 0,
    },
    {
      day: " ",
      date: "",
      slots: 0,
    },
    {
      day: " ",
      date: "",
      slots: 0,
    },
    {
      day: " ",
      date: "",
      slots: 0,
    },
    {
      day: " ",
      date: "",
      slots: 0,
    },
    {
      day: " ",
      date: "",
      slots: 0,
    },
    {
      day: " ",
      date: "",
      slots: 0,
    },
  ]);
  const [scrollTopBtn, setScrollTopBtn] = useState(false);
  // const numbers = [
  //     {
  //         day: " ",
  //         date: "",
  //         slots: 13
  //     },
  //     {
  //         day: " ",
  //         date: "",
  //         slots: 12
  //     },
  //     {
  //         day: " ",
  //         date: "",
  //         slots: 12
  //     },
  //     {
  //         day: " ",
  //         date: "",
  //         slots: 12
  //     },
  //     {
  //         day: " ",
  //         date: "",
  //         slots: 10
  //     },
  //     {
  //         day: " ",
  //         date: "",
  //         slots: 13
  //     },
  //     {
  //         day: " ",
  //         date: "",
  //         slots: 13
  //     }
  // ]
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  var goBackDays = 7;
  var today = new Date();
  var month = today.getMonth();
  var daysSorted = [];

  for (var i = 0; i < goBackDays; i++) {
    var newDate = new Date(today.setDate(today.getDate() - 13));
    let day = newDate.getDay() - 1;
    daysSorted.push(days[day]);
  }

  // console.log(daysSorted)
  for (let i = 0; i < daysSorted.length; i++) {
    if (daysSorted[i] === undefined) {
      if (daysSorted[i + 1] === "Sun") {
        daysSorted[i] = "Sat";
      }
      if (daysSorted[i + 1] === "Sat") {
        daysSorted[i] = "Fri";
      }
      if (daysSorted[i + 1] === "Fri") {
        daysSorted[i] = "Thu";
      }
      if (daysSorted[i + 1] === "Thu") {
        daysSorted[i] = "Wed";
      }
      if (daysSorted[i + 1] === "Wed") {
        daysSorted[i] = "Tue";
      }
      if (daysSorted[i + 1] === "Tue") {
        daysSorted[i] = "Mon";
      }
      if (daysSorted[i + 1] === "Mon") {
        daysSorted[i] = "Sun";
      }
    }
  }
  const Months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  var tmonth = Months[month];
  var arrDates = [];
  var currDate = new Date();
  const isMonthGoingToChange = currDate;

  useEffect(() => {
    async function getSchedule() {
      // Get and store slots count
      console.log("hemlo", numbers);
      getScheduleTimingListReset();

      var copyOfNumbers = numbers.slice();
      var copyOfNumbers2 = numbers.slice();
      for (i in copyOfNumbers) {
        if (copyOfNumbers[i].day === "Mon") {
          copyOfNumbers[i].day = "Monday";
        }
        if (copyOfNumbers[i].day === "Tue") {
          copyOfNumbers[i].day = "Tuesday";
        }
        if (copyOfNumbers[i].day === "Wed") {
          copyOfNumbers[i].day = "Wednesday";
        }
        if (copyOfNumbers[i].day === "Thu") {
          copyOfNumbers[i].day = "Thursday";
        }
        if (copyOfNumbers[i].day === "Fri") {
          copyOfNumbers[i].day = "Friday";
        }
        if (copyOfNumbers[i].day === "Sat") {
          copyOfNumbers[i].day = "Saturday";
        }
        if (copyOfNumbers[i].day === "Sun") {
          copyOfNumbers[i].day = "Sunday";
        }
        copyOfNumbers[i].date = moment().add(i, "days").format("YYYY-MM-DD");
        const params = {
          date: copyOfNumbers[i].date,
          day: copyOfNumbers[i].day,
          doctor_id: doctorParams.doctor,
        };
        // getScheduleTimingList(params);
        // console.log(" getScheduleTimingList i=" + i, getScheduleTimingListState)
        // getScheduleTimingListReset()

        const slot = await axios
          .get(
            `${config.api.base_url}schedule-timing/get-slot-by-day-and-date?day=${params.day}&date=${params.date}&doctor_id=${params.doctor_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getPatientToken()}`,
              },
            }
          )
          .then((res) => {
            return res.data.data.result.schedule_timing_slot[0].time_slots
              .length;
          })
          .catch((e) => {
            console.log(e);
          });
        // console.log("slots", slot)
        copyOfNumbers2[i].slots = slot || 0;
      }

      SetNumbers(copyOfNumbers2);
      console.log("Slots count updated", copyOfNumbers);
      console.log("Slots count updated 2", copyOfNumbers2);
    }
    getSchedule();
  }, [getScheduleTimingList, SetNumbers]);

  const showModal = () => {
    console.log("Show Modal");
    if (timeValue) {
      setIsModalVisible(true);
    } else {
      Modal.error({
        content: (
          <div>
            <p>Please select time slot</p>
          </div>
        ),

        onOk() {},
      });
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    let text = e.target.value;
    const DateDay = text.split("");
    var dd = DateDay[0] + DateDay[1];
    let dayNum = DateDay[2] + DateDay[3] + DateDay[4];
    let yr = currDate.getFullYear();
    let mon = currDate.getMonth();
    mon = mon + 1;
    let sendDate = yr + "-" + mon + "-" + dd;
    setSendDate(sendDate);
    // console.log(sendDate)
    setValue(e.target.value);
    let sendDay = "";
    if (dayNum === "Mon") {
      sendDay = "Monday";
    }
    if (dayNum === "Tue") {
      sendDay = "Tuesday";
    }
    if (dayNum === "Wed") {
      sendDay = "Wednesday";
    }
    if (dayNum === "Thu") {
      sendDay = "Thursday";
    }
    if (dayNum === "Fri") {
      sendDay = "Friday";
    }
    if (dayNum === "Sat") {
      sendDay = "Saturday";
    }
    if (dayNum === "Sun") {
      sendDay = "Sunday";
    }
    console.log(sendDate);
    // console.log(dayNum)
    const params = {
      day: sendDay,
      date: sendDate,
      doctor_id: doctorParams.doctor,
    };

    console.log("new params", params);
    getScheduleTimingList(params);
  };

  const onTimeChange = (e) => {
    console.log("radio checked", e.target.value);
    setTimeValue(e.target.value);
    const selectedSlot = TimeSlots.filter(
      (item) => timeSlotFormat(item.start_time) == e.target.value
    );
    console.log("selected" + timeSlotFormat(selectedSlot[0].end_time));
    setEndTime(timeSlotFormat(selectedSlot[0].end_time));
    setSelected(false);
    setstime(false);
    // console.log(e.target.value)
  };

  // console.log(selected)
  var day = new Date();
  console.log(day); // Apr 30 2000

  //date increment
  const d = new Date();
  function dateRange(startDate, endDate, steps = 1) {
    const dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      dateArray.push(currentDate.getDate());
      currentDate.setUTCDate(currentDate.getDate() + steps);
    }

    return dateArray;
  }

  var StartDate = new Date();
  var yyyy = StartDate.getFullYear();
  let mm = StartDate.getMonth() + 1;
  let dd = StartDate.getDate();
  const EndDate = new Date();
  EndDate.setDate(EndDate.getDate() + 6);
  let dds = EndDate.getDate();
  let mms = EndDate.getMonth() + 1;
  var yyyys = EndDate.getFullYear();
  if (dd < 10) dd = "0" + dd;
  if (dds < 10) dds = "0" + dds;
  if (mm < 10) mm = "0" + mm;
  if (mms < 10) mms = "0" + mms;

  var datess = dateRange(
    "" + yyyy + "-" + mm + "-" + dd,
    "" + yyyys + "-" + mms + "-" + dds
  );
  //end increment

  for (let x = 0; x < datess.length; x++) {
    arrDates.push(datess[x]);
  }
  //for (let x = 0; x < goBackDays; x++) {
  //    arrDates.push(currDate.getDate() + x)
  //}
  console.log("date1s", arrDates);

  for (let i = 0; i < goBackDays; i++) {
    numbers[i].day = daysSorted[i];
    if (arrDates[i] / 10 < 1) {
      arrDates[i] = "0" + arrDates[i];
    }
    numbers[i].date = arrDates[i];
  }

  function Timings() {
    if (TimeSlots.length === 0) {
      return <h3>No Slots Available</h3>;
    } else {
      return (
        <div>
          {TimeSlots.length > 0 &&
            TimeSlots.map((item, i) => {
              let startTimeObj = moment(item.start_time, "HH:mm");
              //let current = new Date();
              // let curtime = current.getHours() + ":" + current.getMinutes()
              //console.log("curr time", item.booked,startTimeObj);
              // console.log("Diff = ", moment().isAfter(startTimeObj))
              //if ((curtime > item.start_time && item.booked == true) || curtime < item.start_time ) {

              //}
              return (
                <TimeRadioButton
                  onChange={onTimeChange}
                  disabled={
                    item.booked ||
                    (moment().isSame(sendDate, "d") &&
                      moment().isAfter(startTimeObj))
                  }
                  // Checking if today && start time is after curr hour only then enable button else disable
                  value={timeSlotFormat(item.start_time)}
                  className="col-sm-2 col-12 mr-4 mb-4 dateSlotBorder"
                >
                  {console.log("item", item)}
                  {/* {console.log("compare", moment().format("HH") - parseInt(item.start_time))} */}
                  <div className="time-slot-head text-center">
                    <b className="bold-heading">
                      {timeSlotFormat(item.start_time)}
                    </b>
                  </div>
                </TimeRadioButton>
              );
            })}
        </div>
      );
    }
  }

  function Slots() {
    return (
      <div className="row mt-5">
        <div className="col-12">
          {numbers.length > 0 &&
            numbers.map(({ day, date, slots }) => {
              // console.log("updated numbers");
              if (
                parseInt(moment().format("DD")) >=
                parseInt(moment().endOf("month").format("DD") - 7)
              ) {
                if (date == 1) {
                  tmonth = Months[month + 1];
                }
              }
              return (
                <SlotRadioButton
                  value={date + day}
                  className="col-sm-2 col-12 mb-3 mr-5 p-4 dateSlotBorder"
                >
                  <div className="time-card-head">
                    <b className="bold-heading d-flex justify-content-center">
                      {day}, {tmonth} {date}
                    </b>
                    <div className="d-flex justify-content-center">
                      {slots} Slots
                    </div>
                  </div>
                </SlotRadioButton>
              );
            })}
        </div>
      </div>
    );
  }

  function getAge(dateString) {
    var today = new Date();
    var expDate = new Date(dateString);
    var experience = today.getFullYear() - expDate.getFullYear();
    var m = today.getMonth() - expDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < expDate.getDate())) {
      experience--;
    }
    return experience;
  }
  let QuaValues = [];
  const date = new Date(doctorData.exprience).toLocaleDateString("us");
  if (!doctorData.speciality) {
    for (let i = 0; i < doctorData.speciality.length; i++) {
      QuaValues[i] = doctorData.speciality[i].value;
    }
  }
  let currMon = currDate.getMonth() + 1;
  let defaultDate = currDate.getFullYear() + "-" + currMon + "-" + arrDates[0];
  let dayNum = numbers[0].day;
  let defaultDay = "";
  if (dayNum === "Mon") {
    defaultDay = "Monday";
  }
  if (dayNum === "Tue") {
    defaultDay = "Tuesday";
  }
  if (dayNum === "Wed") {
    defaultDay = "Wednesday";
  }
  if (dayNum === "Thu") {
    defaultDay = "Thursday";
  }
  if (dayNum === "Fri") {
    defaultDay = "Friday";
  }
  if (dayNum === "Sat") {
    defaultDay = "Saturday";
  }
  if (dayNum === "Sun") {
    defaultDay = "Sunday";
  }
  // console.log(defaultDate,defaultDay)
  if (typeof sendDate === undefined) {
    setSendDate(defaultDate);
  }

  function setData() {
    if (isModalVisible) {
      const data = {
        doctor: doctor,
        type: type,
        date: sendDate,
        slot: [timeValue, endTime],
        self_fee: getDoctorDetailState.data.data.data.self_fee,
        couple_fee: getDoctorDetailState.data.data.data.couple_fee,
        family_fee: getDoctorDetailState.data.data.data.family_fee,
      };
      savePaymentData(data);
      console.log("save payment", data);
    }
  }

  //CALLBACKS
  useEffect(() => {
    window.scrollTo(0, 0);
    // setData()
    const Defaultparams = {
      day: defaultDay,
      date: defaultDate,
      doctor_id: doctorParams.doctor,
    };
    console.log("Defaultparams", Defaultparams);
    getDoctorDetail(doctorParams);
    getScheduleTimingList(Defaultparams);
    return () => {
      getDoctorDetailReset();
      getScheduleTimingListReset();
    };
  }, []);

  useEffect(() => {
    if (getDoctorDetailState.apiState === "success") {
      // console.log(getDoctorDetailState.data.data.data);
      setDoctorData({
        ["id"]: getDoctorDetailState.data.data.data.hospital._id,
        ["speciality"]: getDoctorDetailState.data.data.data.speciality,
        ["fname"]: getDoctorDetailState.data.data.data.fname,
        ["lname"]: getDoctorDetailState.data.data.data.lname,
        ["gender"]: getDoctorDetailState.data.data.data.gender,
        ["qualification"]: getDoctorDetailState.data.data.data.qualification,
        ["profile_picture"]:
          getDoctorDetailState.data.data.data.profile_picture,
        ["description"]: getDoctorDetailState.data.data.data.description,
        ["tier_1_fee"]: getDoctorDetailState.data.data.data.tier_1_fee,
        ["tier_2_fee"]: getDoctorDetailState.data.data.data.tier_2_fee,
        ["short_description"]:
          getDoctorDetailState.data.data.data.short_description,
        ["exprience"]: getDoctorDetailState.data.data.data.exprience,
        ["salutation"]:
          getDoctorDetailState.data.data.data.salutation != null
            ? getDoctorDetailState.data.data.data.salutation.value
            : "",
      });
    }
  }, [getDoctorDetailState]);

  useEffect(() => {
    if (getScheduleTimingListState.apiState === "success") {
      //when there are basic Slots
      if (
        getScheduleTimingListState.list &&
        getScheduleTimingListState.list.schedule_timing_slot.length >= 1
      ) {
        if (
          getScheduleTimingListState.list.schedule_timing_slot[0].time_slots
            .length > 0
        ) {
          const timings = [];
          const x =
            getScheduleTimingListState.list.schedule_timing_slot[0].time_slots.map(
              (T) => {
                console.log("timings" + T);
                timings.push(T);
              }
            );
          console.log("timings", timings);

          setTimeSlots(timings);
        } else {
          setTimeSlots([]);
        }
      } else {
        setTimeSlots([]);
      }
    }
  }, [getScheduleTimingListState]);

  useEffect(() => {
    setData();
  }, [isModalVisible]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setScrollTopBtn(true);
      } else {
        setScrollTopBtn(false);
      }
    });
  }, []);

  return (
    <>
      <TopContainer>
        <img
          className="align-center mobile-height"
          src="./images/lilac-insights-logo.svg"
          alt="exp"
        />
        {/* <Title level={1}>Expert Details</Title> */}
      </TopContainer>

      <PageWrap>
        {/* {getScheduleTimingListState.list.available_slots > 0 ?  */}

        <div className="container">
          <img
            alt="right-bg"
            className="doctorRightBg1 hideMobile"
            // style={{ top: "20%" }}
            src="./images/svg/doctorbgRight.svg"
          />
          <img
            alt="right-bg"
            className="doctorLeftBg hideMobile"
            style={{ top: "50%" }}
            src="./images/svg/doctorbgLeft.svg"
          />
          <div className="row">
            <div className="col-sm-12">
              <h1 className="dtDetailsHead">Expert Details </h1>
              <DoctorDetailBlock doctorData={doctorData} isExperience={false} />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-12 mb-4">
              <Radio.Group
                onChange={onChange}
                value={value}
                defaultValue={arrDates[0] + numbers[0].day}
                buttonStyle="solid"
              >
                <Slots />
              </Radio.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-12 mb-4">
              <DocDetailsHeading2 className="mb-4">
                Time Slots
              </DocDetailsHeading2>
              {getScheduleTimingListState.apiState === "loading" ? (
                <Skeleton />
              ) : getScheduleTimingListState.apiState === "success" ? (
                <Radio.Group size="large" buttonStyle="solid">
                  <Timings />
                </Radio.Group>
              ) : (
                <h3 className="no-slot-text">No slots available</h3>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 mb-4 text-center">
              <Button
                className="themeBtn"
                size="large"
                disabled={stime}
                shape="round"
                type="primary"
                onClick={showModal}
              >
                Book
              </Button>
              {/* disabled={selected} */}
              <Modal footer={null} visible={isModalVisible} onCancel={handleOk}>
                <div className="container">
                  <div className="flex">
                    <h5>Info</h5>
                  </div>
                  <p className="mt-4">
                    Slot is allocated to you for next 10 min. For seamless
                    experience, please confirm booking with this period
                  </p>
                  <div className="text-right">
                    <Link
                      to={{
                        pathname: "/consultation-booking",
                        state: {
                          type: { type },
                          doctor: { doctor },
                          date: { sendDate },
                          slot: [timeValue],
                          end_time: { endTime },
                        },
                      }}
                    >
                      <Button className="themeBtn">Ok</Button>
                    </Link>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
        {scrollTopBtn && <ScrollToTop />}
      </PageWrap>
    </>
  );
}

const mapStateToProps = (state) => ({
  getDoctorDetailState: state.getDoctorDetail,
  getScheduleTimingListState: state.getScheduleTimingList,
  savePaymentDataState: state.savePaymentData,
});

const mapDispatchToProps = (dispatch) => ({
  getDoctorDetail: (params) => dispatch(getDoctorDetail(params)),
  getDoctorDetailReset: () => dispatch(getDoctorDetailReset()),
  getScheduleTimingList: (params) => dispatch(getScheduleTimingList(params)),
  getScheduleTimingListReset: () => dispatch(getScheduleTimingListReset()),
  savePaymentData: (params) => dispatch(savePaymentData(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DoctorAppoint);
