import React, { useEffect, useState } from "react";
import { Typography, Modal, Button, Form, Input, InputNumber } from "antd";
import { connect } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { getPatientToken } from "../../utils";
import config from "../../config";
import axios from "axios";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ContactUs = (props) => {
  const { Title } = Typography;
  const onFinish = async (values) => {
    console.log(values);

    axios({
      url: `${config.api.base_url}patient/add-contact-us-enquiry`,
      method: "post",
      data: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getPatientToken()}`,
      },
    }).then((result) => {
      console.log(result.data.message);
      if (result.data.message === "Enquiry Sent Successfully.") {
        Modal.success({
          title: <Title level={2} style={{ textAlign: "center" }}></Title>,
          content: (
            <div>
              <p style={{ textAlign: "center" }}>{result.data.message}</p>
            </div>
          ),
          style: {},
          onOk() {
            console.log(result.data.message);
          },
        });
      }
    });
  };

  window.scrollTo(0, 0);

  return (
    <div className="container">
      <div className="pageWrap">
        <div className="row mt-2">
          <div className="offset-sm-3 col-sm-6 col-12">
            <div className="drConsltBookinWrap">
              <h4 level={1} style={{ textAlign: "center" }}>
                Contact Us
              </h4>
              <Form name="nest-messages" onFinish={onFinish}>
                <Title level={5}>
                  <b style={{ color: "red" }}>*</b> Full name
                </Title>
                <Form.Item
                  name={["name"]}
                  rules={[
                    { required: true, message: "Please enter full name" },
                    {
                      pattern: new RegExp(/^[a-zA-Z\s]*$/),
                      message: "Please enter valid full name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter full name"
                    className="cbTextBorder"
                    size="large"
                  />
                </Form.Item>

                <Title level={5}>
                  <b style={{ color: "red" }}>*</b> Phone
                </Title>
                <Form.Item
                  name={["phone"]}
                  rules={[
                    {
                      required: true,
                      message: "Please enter phone number",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value || value.match(/^[6-9]\d{9}$/)) {
                          return Promise.resolve().then(function (value) {
                            //console.log(value, 'success')
                          });
                        }
                        return Promise.reject(
                          new Error("Please enter valid phone number")
                        );
                      },
                    }),
                  ]}
                  // rules={[{ required: true, message: '* Phone is required!' }]}
                >
                  <Input
                    placeholder="Enter phone"
                    maxLength={10}
                    className="cbTextBorder"
                    size="large"
                  />
                </Form.Item>
                <Title level={5}>
                  <b style={{ color: "red" }}>*</b> Email
                </Title>
                <Form.Item
                  name={["email"]}
                  rules={[
                    { required: true, message: "Please enter email id" },
                    () => ({
                      validator(_, value) {
                        if (
                          !value ||
                          value.match(
                            /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
                          )
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please enter valid email id")
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder="Enter email"
                    className="cbTextBorder"
                    size="large"
                  />
                </Form.Item>
                <Title level={5}>Message</Title>
                <Form.Item name={["message"]}>
                  <Input.TextArea
                    placeholder="Enter Message"
                    className="cbTextBorder"
                    style={{ height: 100 }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item className="text-center">
                  <Button
                    type="primary"
                    className="themeBtn pl-4 pr-4 pl-sm-4 pr-sm-4"
                    htmlType="submit"
                  >
                    Send Message
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
        <div className="row mt-3"></div>
      </div>
    </div>
  );
};
export default ContactUs;
