import React, { useState, useEffect } from "react";
import { Typography, DatePicker, Row, Radio, Space, Modal, Form } from "antd";
import { register } from "../../action/registerAction";
import { saveRegisterData } from "../../action/saveRegisterDataAction";
import moment from "moment";
import { connect } from "react-redux";
import "./GenderDob.css";
//STYLE
import {
  Container,
  Content,
  Left,
  Ximg,
  XTitle,
  Right,
  XButton,
  CardFoot,
  RadioButton,
  Xh6,
  FormItem,
  FormItem2,
  XDTitle,
  RadioButtonD,
  CardFootD1,
  CardFootD2,
  CardFoot1,
  CardFoot2,
  CardFoot3,
} from "./GenderDobStyle";
import { CalenderIcon } from "../../component/CustomIcons";

export const GenderDob = (props) => {
  const {
    patientData,
    register,
    registerState,
    saveRegisterData,
    saveRegisterDataState,
  } = props;

  // let history = useHistory();
  // console.log(history)
  const { Title } = Typography;
  const [form] = Form.useForm();
  const [checkState, setCheckState] = useState(false);
  const [ButtonData, setButtonData] = useState({
    male: { BgColor: "#FFFFFF", click: false },
    female: { BgColor: "#FFFFFF", click: false },
    trans: { BgColor: "#FFFFFF", click: false },
    NB: { BgColor: "#FFFFFF", click: false },
    other: { BgColor: "#FFFFFF", click: false },
  });
  const dateFormat = "YYYY-MM-DD";
  const dats = new Date();
  const [formData, setFormData] = useState({
    gender: "",
    dob: "",
  });

  const Submit = () => {
    //if(saveRegisterDataState.data.gender === ''){
    //    Modal.warning({
    //        content: 'Please enter your gender',
    //    });
    //}
    //else if(!saveRegisterDataState.data.dob){
    //    Modal.warning({
    //        content: 'Please enter your Date of Birth',
    //    });
    //}

    //console.log(saveRegisterDataState.data)
    //if(!saveRegisterDataState.data.interest){
    //    const data = {
    //        fname: saveRegisterDataState.data.fname,
    //        lname: saveRegisterDataState.data.lname,
    //        phone: saveRegisterDataState.data.phone,
    //        email: saveRegisterDataState.data.email,
    //        password: saveRegisterDataState.data.password,
    //        gender: formData.gender,
    //        dob: formData.dob,
    //    }
    //    console.log(data)
    //    props.setSuccess(3)
    //    saveRegisterData(data)
    //    console.log(formData)
    //}else{
    //    //props.setSuccess(3)
    //    //saveRegisterData(data)
    //    //console.log(saveRegisterData)
    //}
    console.log("Received values of form: ");
  };

  const config = {
    rules: [{ type: "object", required: true, message: "Please select time!" }],
  };

  const onFinish = (values) => {
    console.log("Received values of form: ", values);

    setFormData({
      gender: values.gender,
      dob: values.dob,
    });
    if (saveRegisterDataState.data.socialSignin) {
      const data = {
        ...saveRegisterDataState.data,
        gender: values.gender,
        dob: values.dob,
      };
      props.setSuccess(3);
      props.setPatientData({ ...props.patientData, ...data });
      saveRegisterData(data);
    } else {
      const data = {
        gender: values.gender,
        dob: values.dob,
      };
      props.setPatientData({ ...props.patientData, ...data });

      console.log("DATA", data);
      props.setSuccess(3);
      saveRegisterData(data);
    }
  };

  function onDateChange(date, dateString) {
    console.log(date, dateString);

    const date1 = date ? date.format("YYYY-MM-DD") : null;
    console.log(date1);
    setFormData({
      ...formData,
      ["dob"]: date1,
    });
  }
  function onChange(e) {
    // console.log(`radio checked:${e.target.value}`);
    let gen = e.target.value;
    if (gen === "women") {
      setFormData({
        ...formData,
        gender: "female",
      });
    }
    console.log(form.getFieldValue("gender"));
    console.log(gen);
    setFormData({
      ...formData,
      ["gender"]: gen,
    });

    form.setFieldsValue({
      ["gender"]: gen,
    });
  }

  const Click = (gender) => {
    let clicked;
    if (gender === "male") {
      clicked = ButtonData.male.click;
      clicked = !clicked;
      if (clicked === true) {
        setButtonData({
          ...ButtonData,
          ["male"]: {
            BgColor: "linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%)",
            click: true,
          },
          ["female"]: { click: false },
          ["trans"]: { click: false },
          ["NB"]: { click: false },
          ["other"]: { click: false },
        });
      } else {
        setButtonData({
          ...ButtonData,
          ["male"]: {
            BgColor: "#FFFFFF",
            click: false,
          },
        });
      }
    } else if (gender === "female") {
      clicked = ButtonData.female.click;
      clicked = !clicked;
      if (clicked === true) {
        setButtonData({
          ...ButtonData,
          ["female"]: {
            BgColor: "linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%)",
            click: true,
          },
          ["male"]: { click: false },
          ["trans"]: { click: false },
          ["NB"]: { click: false },
          ["other"]: { click: false },
        });
      } else {
        setButtonData({
          ...ButtonData,
          ["female"]: {
            BgColor: "#FFFFFF",
            click: false,
          },
        });
      }
    } else if (gender === "trans") {
      clicked = ButtonData.trans.click;
      clicked = !clicked;
      if (clicked === true) {
        setButtonData({
          ...ButtonData,
          ["trans"]: {
            BgColor: "linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%)",
            click: true,
          },
          ["female"]: { click: false },
          ["male"]: { click: false },
          ["NB"]: { click: false },
          ["other"]: { click: false },
        });
      } else {
        setButtonData({
          ...ButtonData,
          ["trans"]: {
            BgColor: "#FFFFFF",
            click: false,
          },
        });
      }
    } else if (gender === "NB") {
      clicked = ButtonData.NB.click;
      clicked = !clicked;
      if (clicked === true) {
        setButtonData({
          ...ButtonData,
          ["NB"]: {
            BgColor: "linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%)",
            click: true,
          },
          ["female"]: { click: false },
          ["trans"]: { click: false },
          ["male"]: { click: false },
          ["other"]: { click: false },
        });
      } else {
        setButtonData({
          ...ButtonData,
          ["NB"]: {
            BgColor: "#FFFFFF",
            click: false,
          },
        });
      }
    } else if (gender === "other") {
      clicked = ButtonData.other.click;
      clicked = !clicked;
      if (clicked === true) {
        setButtonData({
          ...ButtonData,
          ["other"]: {
            BgColor: "linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%)",
            click: true,
          },
          ["female"]: { click: false },
          ["trans"]: { click: false },
          ["NB"]: { click: false },
          ["male"]: { click: false },
        });
      } else {
        setButtonData({
          ...ButtonData,
          ["other"]: {
            BgColor: "#FFFFFF",
            click: false,
          },
        });
      }
    }

    // console.log(gender)
    setFormData({
      ...formData,
      ["gender"]: gender,
    });
  };

  function Genders() {
    return (
      // <div>
      <div className="genderInliine">
        <Radio.Group
          defaultValue="a"
          value={form.getFieldValue("gender")}
          onChange={onChange}
        >
          <div className="row">
            <div className="col-sm-3 col-4 mb-5">
              <RadioButton
                value="male"
                onClick={() => Click("male")}
                style={{ background: ButtonData.male.BgColor }}
              >
                {ButtonData.male.click ? (
                  <img
                    className="img-fluid"
                    src={process.env.PUBLIC_URL + "/images/man.svg"}
                    alt=""
                  />
                ) : (
                  <img
                    className="img-fluid"
                    src={process.env.PUBLIC_URL + "/images/manP.svg"}
                    alt=""
                  />
                )}
              </RadioButton>
              <div className="radioLb">Man</div>
            </div>
            <div className="col-sm-3 col-4 mb-5">
              <RadioButton
                value="female"
                onClick={() => Click("female")}
                style={{ background: ButtonData.female.BgColor }}
              >
                {ButtonData.female.click ? (
                  <img
                    className="img-fluid"
                    src={process.env.PUBLIC_URL + "/images/WomanW.svg"}
                    alt=""
                  />
                ) : (
                  <img
                    className="img-fluid"
                    src={process.env.PUBLIC_URL + "/images/woman.svg"}
                    alt=""
                  />
                )}
              </RadioButton>
              <div className="radioLb">Woman</div>
            </div>
            <div className="col-sm-3 col-4 mb-5">
              <RadioButton
                value="trans"
                onClick={() => Click("trans")}
                style={{ background: ButtonData.trans.BgColor }}
              >
                {ButtonData.trans.click ? (
                  <img
                    className="img-fluid"
                    src={process.env.PUBLIC_URL + "/images/transW.svg"}
                    alt=""
                  />
                ) : (
                  <img
                    className="img-fluid"
                    src={process.env.PUBLIC_URL + "/images/trans.svg"}
                    alt=""
                  />
                )}
              </RadioButton>
              <div className="radioLb">Transgender</div>
            </div>
            <div className="col-sm-3 col-4 mb-5">
              <RadioButtonD
                value="non-binary"
                onClick={() => Click("NB")}
                style={{ background: ButtonData.NB.BgColor }}
              >
                {ButtonData.NB.click ? (
                  <img
                    className="img-fluid"
                    src={process.env.PUBLIC_URL + "/images/NBW.svg"}
                    alt=""
                  />
                ) : (
                  <img
                    className="img-fluid"
                    src={process.env.PUBLIC_URL + "/images/NB.svg"}
                    alt=""
                  />
                )}
              </RadioButtonD>
              <div className="radioLb">Non-Binary</div>
            </div>
            <div className="col-sm-3 col-4 mb-5">
              <RadioButtonD
                value="other"
                onClick={() => Click("other")}
                style={{ background: ButtonData.other.BgColor }}
              >
                {ButtonData.other.click ? (
                  <img
                    className="img-fluid"
                    src={process.env.PUBLIC_URL + "/images/otherW.svg"}
                    alt=""
                  />
                ) : (
                  <img
                    className="img-fluid"
                    src={process.env.PUBLIC_URL + "/images/other.svg"}
                    alt=""
                  />
                )}
              </RadioButtonD>
              <div className="radioLb">Other</div>
            </div>
          </div>
        </Radio.Group>
      </div>

      // {/* </div> */}
    );
  }

  function disabledDate(current) {
    // let customDate =  moment("20040620", "YYYY-MM-DD").fromNow();
    let customDate = moment();
    return current &&
      moment().diff(
        moment(current, "YYYY-MM-DD").format("YYYY-MM-DD"),
        "days"
      ) > 6574
      ? false
      : true;
  }
  //CALLBACKS

  useEffect(() => {
    console.log("Initial state, Gender page, ", props.patientData);
    Click(props.patientData.gender);
    form.setFieldsValue({
      ["gender"]: props.patientData.gender,
      ["dob"]: props.patientData.dob
        ? moment(props.patientData.dob, "YYYY-MM-DD")
        : "",
    });
    // console.log("gender: ", saveRegisterDataState.data.gender)
    //console.log(form.getFieldValue('gender'))
  }, []);

  useEffect(() => {
    // console.log(registerState)

    if (registerState.apiState === "success") {
      registerState.apiState = "";
      localStorage.setItem("LilacPatientToken", registerState.data.token);
      console.log(registerState.data.token);
      localStorage.setItem(
        "LilacPatient",
        JSON.stringify(registerState.data.patient.patient_id)
      );
      console.log(registerState.data.patient.patient_id);
      setCheckState(true);
      setFormData({
        ...formData,
        ["dob"]: registerState.data.patient.dob,
        ["gender"]: registerState.data.patient.gender,
      });
    } else {
      registerState.apiState = "";
    }
  }, [registerState]);

  return (
    <div className="container-fluid">
      <div className="row pl-lg-5">
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 pl-lg-5">
          <div className="logos-dob">
            <img
              src={
                process.env.PUBLIC_URL + "/images/svg/Lilac-Insights-Logo1.svg"
              }
              alt=""
            />
          </div>

          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <h1 className="regitWelcome mb-1 mt-3">
                Hi {patientData.fname},
              </h1>
              <h6 className="genderLabH6 mb-0">
                Please help us with some more details
              </h6>
            </div>
          </div>
          <Form
            form={form}
            colon={false}
            requiredMark={false}
            onFinish={onFinish}
            className="p-0 pb-5 pb-lg-0"
            // initialValues={{
            //     dob: moment(new Date(), dateFormat),

            // }}
          >
            <div className="row mt-4">
              <div className="col-sm-12">
                <Genders />
                <FormItem2
                  name="gender"
                  rules={[
                    { required: true, message: "Please enter your gender" },
                  ]}
                >
                  {/* <Radio.Group  size="large" buttonStyle="solid" > 
                                          
                                    </Radio.Group> */}
                  <input type="hidden" value={form.getFieldValue("gender")} />
                </FormItem2>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-12">
                <h6 className="genderLabH6 mb-0">Date of Birth</h6>
              </div>
              <div className="col-sm-12 col-12 mt-3">
                <h6 className="Regitlabel" style={{ fontWeight: "500" }}>
                  {" "}
                  DD/MM/YYYY
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12">
                <FormItem
                  name="dob"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Date Of Birth!",
                    },
                  ]}
                  className="datePicker"
                >
                  <DatePicker
                    format={"DD-MM-YYYY"}
                    disabledDate={disabledDate}
                    defaultPickerValue={moment().subtract(18, "years")}
                    className="form-control registerInputBox"
                    style={{ borderRadius: "15px;", display: "block;" }}
                    onChange={onDateChange}
                  />
                </FormItem>
              </div>
            </div>

            <div className="row">
              {/* <div className="col-12 col-sm-5 mb-2">
                <button
                  className="regitBtn"
                  shape="round"
                  type="primary"
                  onClick={()=>props.previous()}
                >
                  Previous
                </button>
              </div> */}
              <div className="col-12 col-sm-6 next-btn">
                <button
                  className="regitBtn"
                  shape="round"
                  type="primary"
                  htmlType="submit"
                >
                  Next
                </button>
              </div>
            </div>
          </Form>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
          <img
            src={process.env.PUBLIC_URL + "/images/svg/loginImg.svg"}
            className="img-dob-fix"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  registerState: state.register,
  saveRegisterDataState: state.saveRegisterData,
});

const mapDispatchToProps = (dispatch) => ({
  register: (params) => dispatch(register(params)),
  saveRegisterData: (params) => dispatch(saveRegisterData(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenderDob);
