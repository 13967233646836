import React, { useEffect, useState, createRef, useRef } from "react";
import { Button, Typography, Drawer, Radio, Rate, Spin, Skeleton } from "antd";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import AtEasy from "../../component/AtEasy/AtEasy";
import LilacInsightsServices from "../../component/LilacInsights/Services";
import {
  getPregHighlightAppList,
  getPregHighlightAppListReset,
} from "../../action/getPregHighlightAppListAction";
import { getChatBot, getChatBotReset } from "../../action/getChatBotAction";
import { login } from "../../action/loginAction";
import ArticleBlock from "../../component/ArticleBlock/ArticleBlock";
import moment from "moment";
import axios from "axios";
import config from "../../config";
import { getPatientToken } from "../../utils";
import {
  getArticleAppList,
  getArticleAppListReset,
} from "../../action/getArticleAppListAction";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "react-bootstrap/Carousel";
import { timeSlotFormat, DaySlotFormat } from "../../utils";
//others
import "bootstrap/dist/css/bootstrap.css";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
} from "@ant-design/icons";
import {
  getServicesList,
  getServicesListReset,
} from "../../action/getServicesListAction";

// Style
import {
  MomBabyImg,
  WomanImg,
  ManImg,
  WomanImgMobile,
  TopContainer,
  CarouselItem,
  GoogleStoreImg,
  AppleStoreImg,
  UserInfo,
  SocialButton,
  HomeWrap,
  Container,
  HomeSmilyWrap,
  HeaderAlign,
  SmilyPara,
  FeelingTodayDiv,
  SmilyHeading,
  SmilyCont,
  WeeklyHeWrap,
  PregaHeading,
  WeeklyHeWrap2,
  YourHelthBg,
  HelthboxTiles,
  DrtestmonialHead,
  DrQuoteLeft,
  BabyRadio,
  MomRadio,
  Selector,
  HomeBabyHeading,
  HomeBabyHeading2,
  WeeklyList,
  MomBabyCard,
  HomeCardPrgaImg,
  HomeCardPrgaPara,
  HomePregaCurveBg,
  HomeCorveData,
  BabySliderDiv,
  HomeWeekTitie,
  WeeklyHeWrapBg,
  HomeBabySubHeading,
  HomeBabySubHeading2,
  XText,
  NoApptDiv,
  DocImg,
  DoctorHeadParent,
  DoctorHeadChild,
  DoctorHeadSlide,
  DoctorParaInline,
  DocCard,
  XCard,
  IconDiv,
  XButton1,
  DoctorContainer,
  XSlider,
} from "./HomeStyle";
import "./Home.css";

import { TribeModal } from "../../component/TribeModal/TribeModal";
import { SendOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
// import { Container } from "react-bootstrap";
//import Slider from "react-slick";
import baby1 from "../../images/baby-1.svg";
import baby2 from "../../images/baby-2.svg";
import baby3 from "../../images/baby-3.svg";
import baby4 from "../../images/baby-4.svg";
import baby5 from "../../images/baby-5.svg";
import baby6 from "../../images/baby-6.svg";
import baby7 from "../../images/baby-7.svg";
import baby8 from "../../images/baby-8.svg";
import baby9 from "../../images/baby-9.svg";
import baby10 from "../../images/baby-10.svg";
import baby11 from "../../images/baby-11.svg";
import baby12 from "../../images/baby-12.svg";
import baby13 from "../../images/baby-13.svg";
import baby14 from "../../images/baby-14.svg";
import baby15 from "../../images/baby-15.svg";
import baby16 from "../../images/baby-16.svg";
import baby17 from "../../images/baby-17.svg";
import baby18 from "../../images/baby-18.svg";
import baby19 from "../../images/baby-19.svg";
import baby20 from "../../images/baby-20.svg";
import baby21 from "../../images/baby-21.svg";
import baby22 from "../../images/baby-22.svg";
import baby23 from "../../images/baby-23.svg";
import baby24 from "../../images/baby-24.svg";
import baby25 from "../../images/baby-25.svg";
import baby26 from "../../images/baby-26.svg";
import baby27 from "../../images/baby-27.svg";
import baby28 from "../../images/baby-28.svg";
import baby29 from "../../images/baby-29.svg";
import baby30 from "../../images/baby-30.svg";
import baby31 from "../../images/baby-31.svg";
import baby32 from "../../images/baby-32.svg";
import baby33 from "../../images/baby-33.svg";
import baby34 from "../../images/baby-34.svg";
import baby35 from "../../images/baby-35.svg";
import baby36 from "../../images/baby-36.svg";
import baby37 from "../../images/baby-37.svg";
import baby38 from "../../images/baby-38.svg";
import baby39 from "../../images/baby-39.svg";
import baby40 from "../../images/baby-40.svg";
import family from "../../images/home_top.svg";
import noAppointment from "../../images/no_appointment.svg";
import HealthBlock from "../../component/HealthBlock/HealthBlock";
import CarouselBlock from "../../component/CarouselBlock/CarouselBlock";
import MoodOMeter from "../../component/MoodOMeter/MoodOMeter";
import Resource from "../../component/Resource/Resource";
import ScrollToTop from "../../component/ScrollToTop/ScrollToTop";
import Header from "../../component/Header/Header";

const Home = (props) => {
  //CONSTANTS
  const {
    getPregHighlightAppList,
    getPregHighlightAppListReset,
    getPregHighlightAppListState,
    getChatBot,
    getChatBotReset,
    getChatBotState,
    getArticleAppList,
    getArticleAppListReset,
    getArticleAppListState,
    getServicesListState,
    getServicesList,
    getServicesListReset,
  } = props;
  const { Title } = Typography;
  let page_week =
    parseInt(localStorage.getItem("week")) > 0
      ? parseInt(localStorage.getItem("week"))
      : 0;
  let fullName = JSON.parse(localStorage.getItem("name"));
  let name = `${fullName.firstName} ${fullName.lastName}`;
  let firstName = `${fullName.firstName}`;
  let tribe = JSON.parse(localStorage.getItem("tribe"));
  let gender = JSON.parse(localStorage.getItem("gender"));
  let conceivedStatus = JSON.parse(localStorage.getItem("conceive_status"));
  let week = JSON.parse(localStorage.getItem("week"));
  let interest = JSON.parse(localStorage.getItem("interest")) || [];
  var today = moment();
  let getLmpDate = JSON.parse(localStorage.getItem("lmp"));
  const [articleData, setArticleData] = useState("");

  const paramsPhAL = {
    week: page_week,
    //"week":  6
  };

  //STATES
  const [pageWeek, setpageWeek] = useState(null);
  console.log("page week", pageWeek + 0);
  // const [page_week , setPage_week] = useState(JSON.parse(localStorage.getItem('week')) > 0 ? JSON.parse(localStorage.getItem('week')):1)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState([]);
  const [problemChecked, setProblemChecked] = useState(false);
  const [feelingChecked, setFeelingChecked] = useState(false);
  const [sendMessage, setSendMessage] = useState("");
  const [recievedMsg, setrecievedMsg] = useState([]);
  const [Testimonials, setTestimonials] = useState([]);
  const [weekData, setWeekData] = useState({
    highlights_of_the_week_mom: [],
    highlights_of_the_week_baby: [],
    precautions_of_the_week_mom: [],
    precautions_of_the_week_baby: [],
    recommendation_for_you_of_the_week_mom: [],
    recommendation_for_you_of_the_week_baby: [],
    days_left: "",
    height: "",
    weight: "",
    size: "",
    size_img: "",
    lmp: "",
    week: "",
  });
  const [imageIndex, setImageIndex] = useState(page_week);
  const [patientWeek, setPatientWeek] = useState(0);
  const [appointments, setAppointments] = useState([]);
  const [activeCard, setActiveCard] = useState("mom");
  const [loadingChat, setLoadingChat] = useState(false);
  const [loadingAppointments, setLoadingAppointments] = useState(false);
  const [scrollTopBtn, setScrollTopBtn] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const images = [
    " ",
    baby1,
    baby2,
    baby3,
    baby4,
    baby5,
    baby6,
    baby7,
    baby8,
    baby9,
    baby10,
    baby11,
    baby12,
    baby13,
    baby14,
    baby15,
    baby16,
    baby17,
    baby18,
    baby19,
    baby20,
    baby21,
    baby22,
    baby23,
    baby24,
    baby25,
    baby26,
    baby27,
    baby28,
    baby29,
    baby30,
    baby31,
    baby32,
    baby33,
    baby34,
    baby35,
    baby36,
    baby37,
    baby38,
    baby39,
    baby40,
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const CustomLoader = () => {
    return (
      <div className="custom-loader">
        <img src="./images/panda.svg" alt="Loading" className="img-fluid" />
        <div className="dot-container">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    );
  };

  const showDrawer = () => {
    setVisible(true);
    const data = {
      start_new_chat: true,
    };
    getChatBot(data);
  };

  const onClose = () => {
    setVisible(false);
    setrecievedMsg([]);
  };
  let d = 100000;
  const myRef = useRef();
  const onselect = (e) => {
    // console.log('radio problemChecked', e.target.value);
    setProblemChecked(true);
    let obj = getChatBotState.data.options_for_new_question.find(
      (o) => o.option_code === e.target.value
    );
    // console.log(obj.option_value);
    let msg = { text: obj.option_value, align: "right" };
    recievedMsg.push(msg);
    const data = {
      answer: e.target.value,
    };
    // console.log(data);
    getChatBot(data);
    d = d + 100000;
    // myRef.current.scrollTo(0,  10000 * 100000)
    myRef.current.scrollTop = myRef.current.scrollTop * 10000;
  };

  const onFeelingselect = (e) => {
    // console.log("radio problemChecked", e.target.value);
    let obj = getChatBotState.data.options_for_new_question.find(
      (o) => o.option_code === e.target.value
    );
    // console.log(obj.option_value);
    let msg = { text: obj.option_value, align: "right" };
    recievedMsg.push(msg);
    const data = {
      answer: e.target.value,
    };
    // console.log(data);
    getChatBot(data);
    setFeelingChecked(true);
    d = d + 100000;
    //myRef.current.scrollBy(0,  10000 * 100000)
    myRef.current.scrollTop = myRef.current.scrollTop * 10000;
  };

  const sendMesage = (e) => {
    e.preventDefault();
    let msg = { text: sendMessage, align: "right" };
    recievedMsg.push(msg);
    setSendMessage("");
    if (feelingChecked && problemChecked && recievedMsg.length > 10) {
      let lastMsg = `it must have been really tough to go through this experience and share it with me. Thank you ${name} for reaching out. `;
      let msg = { text: lastMsg, align: "left" };
      recievedMsg.push(msg);
    }
  };

  const getProblems = () => {
    if (getChatBotState.data.options_for_new_question.length > 0) {
      return (
        <div className="col-sm-12 col-12 mb-4">
          <Radio.Group buttonStyle="solid" onChange={onselect}>
            {getChatBotState.data.options_for_new_question.map((item) => (
              <Radio.Button
                value={item.option_code}
                className="mr-2 mb-2 option dateSlotBorder"
              >
                {item.option_value}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
      );
    } else {
      return false;
    }
  };

  const getFeelings = () => {
    if (getChatBotState.data.options_for_new_question.length > 0) {
      return (
        <div className="col-sm-12 col-12 mb-4">
          <Radio.Group buttonStyle="solid" onChange={onFeelingselect}>
            {getChatBotState.data.options_for_new_question.map((item) => (
              <Radio.Button
                value={item.option_code}
                className="mr-2 mb-2 option dateSlotBorder"
              >
                {item.option_value}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
      );
    } else {
      return false;
    }
  };

  function Testimonail() {
    return (
      <>
        {Testimonials.map((review) => (
          <CarouselItem>
            <div className="customslides">
              <div className="row" alt="First slide">
                <div className="col-sm-12">
                  <h1 className="drtestmonialHead">{review.patient_id.name}</h1>
                </div>
                <div className="col-sm-12 mt-1 mb-4 text-center">
                  <Rate disabled defaultValue={review.rating} />
                </div>
                <div className="col-sm-12 text-center">
                  <p className="mb-0">
                    <sup className="drQuoteLeft">
                      <img
                        className=""
                        src={
                          process.env.PUBLIC_URL +
                          "./images/svg/quotes-left.svg"
                        }
                        alt=""
                      />
                    </sup>{" "}
                    {review.comment}
                    <sub className="drQuoteRight">
                      <img
                        className=""
                        src={
                          process.env.PUBLIC_URL +
                          "./images/svg/quotes-right.svg"
                        }
                        alt=""
                      />
                    </sub>
                  </p>
                </div>
              </div>
            </div>
          </CarouselItem>
        ))}
      </>
    );
  }

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <RightOutlined style={{ fontSize: 22 }} />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <LeftOutlined style={{ fontSize: 22 }} />
      </div>
    );
  };

  const handleRightArrowClick = () => {
    setActiveCard("baby");
  };

  const handleLeftArrowClick = () => {
    setActiveCard("mom");
  };

  const handleToggle = () => {
    activeCard === "mom" ? setActiveCard("baby") : setActiveCard("mom");
  };

  let drawerWidth;
  if (window.innerWidth <= 320) {
    drawerWidth = "100%";
  } else if (window.innerWidth > 320 && window.innerWidth <= 576) {
    drawerWidth = "80%";
  } else if (window.innerWidth > 576 && window.innerWidth <= 768) {
    drawerWidth = "60%";
  } else if (window.innerWidth > 768 && window.innerWidth <= 1024) {
    drawerWidth = "40%";
  } else if (window.innerWidth > 1024) {
    drawerWidth = "30%";
  }

  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 7,
    centerMode: true,
    centerPadding: 0,
    nextArrow: pageWeek !== 40 && <NextArrow />,
    prevArrow: pageWeek !== 1 && <PrevArrow />,
    initialSlide: page_week,
    swipeToSlide: true,
    focusOnSelect: false,
    beforeChange: (current, next) => {
      // console.log("setting", next);
      // alert(next)
      setImageIndex(next);
      // setPage_week(next +1)
      setpageWeek(next);
      page_week = next;
    },
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          initialSlide: page_week,
        },
      },
    ],
  };

  //CALLBACKS
  useEffect(() => {
    let paramsAL = {
      // "week": pageWeek,
      category: encodeURIComponent(
        interest.length > 0 && interest[0] !== null ? interest : null
      ),
    };

    if (
      gender === "female" &&
      interest.includes("Pregnancy & Reproductive Wellness") &&
      conceivedStatus === "Already Conceived" &&
      pageWeek
    ) {
      paramsAL.week = pageWeek;
      paramsAL.category = encodeURIComponent(
        "Pregnancy & Reproductive Wellness"
      );
      getArticleAppList(paramsAL);
    } else if (
      !(
        gender === "female" &&
        interest.includes("Pregnancy & Reproductive Wellness") &&
        conceivedStatus === "Already Conceived"
      )
    ) {
      getArticleAppList(paramsAL);
    }
  }, [pageWeek]);

  useEffect(() => {
    setpageWeek(
      today.diff(getLmpDate, "week") > 0
        ? today.diff(getLmpDate, "week") + 1
        : 1
    );
    setPatientWeek(
      today.diff(getLmpDate, "week") > 0
        ? today.diff(getLmpDate, "week") + 1
        : 1
    );
    // if(interest.includes("Pregnancy & Reproductive Wellness")){
    //   console.log("WEEK 2: ",paramsPhAL);
    //   getPregHighlightAppList(paramsPhAL);
    // }
    axios({
      url: `${config.api.base_url}testimonial/list/`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getPatientToken()}`,
      },
    })
      .then((respons) => {
        // console.log(respons.data)
      })
      .catch((error) => {
        console.log(error);
      });
    const data = [
      {
        _id: "61388a82a42c695fdcf9d093",
        patient_id: {
          _id: "613f50c1bab60ee2115d60c7",
          name: "Kshitija Duraphe",
        },
        rating: 4,
        comment: "Excellent consultation",
        doctor_id: {
          _id: "6129d72025b7290647da9a01",
          fname: "Syeda",
          lname: "Ruksheda",
          profile_picture: "db5a03ab-d471-4bbb-9482-3682a353e2af.jpeg",
        },
        status: "approved",
      },
      {
        _id: "61389796a42c695fdcf9d099",
        patient_id: {
          _id: "6138cee78b740d790ac422c4",
          name: "Asifa Shaikh",
          profile_pic: "",
        },
        rating: 2,
        comment: "Good experince I had with doctor consultancy",
        doctor_id: {
          _id: "6129d72025b7290647da9a01",
          fname: "Syeda",
          lname: "Ruksheda",
          profile_picture: "db5a03ab-d471-4bbb-9482-3682a353e2af.jpeg",
        },
        status: "rejected",
      },
      {
        _id: "6138a542a42c695fdcf9d09c",
        patient_id: {
          _id: "613071c0b57292fde5aaac55",
          name: "asif shaikh",
          profile_pic: "",
        },
        rating: 3,
        comment: "Best experince I had with doctor consultancy",
        doctor_id: {
          _id: "6129d72025b7290647da9a01",
          fname: "Syeda",
          lname: "Ruksheda",
          profile_picture: "db5a03ab-d471-4bbb-9482-3682a353e2af.jpeg",
        },
      },
    ];
    setTestimonials(data);
    return () => {
      getPregHighlightAppListReset();
    };
  }, []);

  useEffect(() => {
    const imageParams = {
      week: pageWeek,
    };
    if (interest.includes("Pregnancy & Reproductive Wellness")) {
      if (pageWeek) {
        getPregHighlightAppList(imageParams);
      }
    }
  }, [pageWeek]);

  useEffect(() => {
    if (getChatBotState.apiState === "loading") {
      setLoadingChat(true);
    } else if (getChatBotState.apiState === "success") {
      // console.log(getChatBotState.data);
      if (recievedMsg.length > 0) {
        let msg = {
          text: getChatBotState.data.response_for_previous_question,
          align: "left",
        };
        recievedMsg.push(msg);
        let msg1 = { text: getChatBotState.data.new_question, align: "left" };
        recievedMsg.push(msg1);
        let options = getFeelings();
        if (options != false) {
          let msg2 = { text: options, align: "left" };
          // console.log(options);
          recievedMsg.push(msg2);
        }
      } else {
        let msg = { text: getChatBotState.data.welcome_message, align: "left" };
        recievedMsg.push(msg);
        let msg1 = { text: getChatBotState.data.new_question, align: "left" };
        recievedMsg.push(msg1);
        let options = getProblems();
        if (options != false) {
          let msg2 = { text: options, align: "left" };
          // console.log(options);
          recievedMsg.push(msg2);
        }
      }
      setLoadingChat(false);
    }
  }, [getChatBotState]);

  useEffect(() => {
    if (getPregHighlightAppListState.apiState === "success") {
      // console.log("getPregHighlightAppListState", getPregHighlightAppListState);
      setWeekData({
        ["highlights_of_the_week_mom"]:
          getPregHighlightAppListState?.data[0]?.mom.highlights_of_the_week,
        ["highlights_of_the_week_baby"]:
          getPregHighlightAppListState?.data[0]?.baby.highlights_of_the_week,

        ["precautions_of_the_week_mom"]:
          getPregHighlightAppListState?.data[0]?.mom.precautions,
        ["precautions_of_the_week_baby"]:
          getPregHighlightAppListState?.data[0]?.baby.precautions,

        ["recommendation_for_you_of_the_week_mom"]:
          getPregHighlightAppListState?.data[0]?.mom.recommendation_for_you,
        ["recommendation_for_you_of_the_week_baby"]:
          getPregHighlightAppListState?.data[0]?.baby.recommendation_for_you,

        ["height"]: getPregHighlightAppListState.data[0]?.height,
        ["days_left"]:
          getPregHighlightAppListState.data[0]?.days_left_for_delivery,
        ["weight"]: getPregHighlightAppListState.data[0]?.weight,
        ["size_img"]: getPregHighlightAppListState.data[0]?.size_image_path,
        ["lmp"]: getPregHighlightAppListState.data[0]?.lmp,
        ["week"]: getPregHighlightAppListState.data[0]?.week,
        // ['week']: pageWeek,
        ["size"]: getPregHighlightAppListState.data[0]?.size,
        ["description_for_baby"]:
          getPregHighlightAppListState.data[0]?.description_for_baby,
        ["description_for_mom"]:
          getPregHighlightAppListState.data[0]?.description_for_mom,
      });
    }
  }, [getPregHighlightAppListState]);

  useEffect(() => {
    if (getArticleAppListState.apiState === "success") {
      // console.log("in use effect", getArticleAppListState.data);s
      setArticleData(getArticleAppListState.data);
    }
  }, [getArticleAppListState]);
  // console.log(recievedMsg)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setScrollTopBtn(true);
      } else {
        setScrollTopBtn(false);
      }
    });
  }, []);

  if (visible) {
    setTimeout(() => {
      document.querySelector(".message-area").scrollTo(0, 9999);
    }, 500);
  }

  const getUpcomingAppointments = async () => {
    try {
      setLoadingAppointments(true);
      const response = await axios.get(
        `${config.api.base_url}patient/upcoming-appointments`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getPatientToken()}`,
          },
        }
      );
      setAppointments(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingAppointments(false);
    }
  };

  useEffect(() => {
    getUpcomingAppointments();
    getServicesList();
  }, []);

  useEffect(() => {
    console.log("2---------------------------in home", getServicesListState);
    if (getServicesListState.apiState === "success") {
      setServiceList(getServicesListState?.data);
    }
    getServicesListReset();
  }, [getServicesListState]);

  function Appointments(props) {
    function getSpeciality(spec) {
      let QuaValues = [];
      for (let i = 0; i < spec.length; i++) {
        QuaValues[i] = spec[i].value;
      }
      return QuaValues.join(" | ");
    }
    return (
      <div className="container">
        <h2 className="headingH2 heading-home text-center">
          Upcoming Appointments
        </h2>
        {loadingAppointments ? (
          <div className=" row text-center mt-2">
            <Spin
              spinning={loadingAppointments}
              tip="Loading Upcoming Appointments..."
            />
          </div>
        ) : props.appointments.length > 0 ? (
          <>
            <div className="carousel carouselWWrap customCarouselArrow mb-5">
              <Carousel
                showIndicators
                showControls
                data-interval="false"
                className="carousel"
                nextIcon={
                  <img width="75" src="/images/arrow-right.svg" alt="#" />
                }
                prevIcon={
                  <img width="75" src="/images/arrow-right.svg" alt="#" />
                }
              >
                {props.appointments.map((item) => (
                  <Carousel.Item class="carousel-item">
                    <div className="p-2">
                      <div class="row sc">
                        <div className="row align-items-center sc">
                          <div className="col-md-6 col-sm-6 col-xl-6 mb-2 mt-2 pl-2 pr-2 text-center">
                            <div className="appmntImg">
                              <img
                                style={{ width: "100%", maxHeight: "250px" }}
                                class="img-fluid svf"
                                src={
                                  item.doctor_id.profile_picture
                                    ? `${config.api.s3_url}doctor/${item.doctor_id.profile_picture}`
                                    : item.doctor_id.gender.toLowerCase() ==
                                      "male"
                                    ? `/images/avatar_male.svg`
                                    : item.doctor_id.gender.toLowerCase() ==
                                      "female"
                                    ? `/images/avatar_female.svg`
                                    : `/images/avatar_transgender.svg`
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6 col-xl-6 text-center">
                            <div className="docInfo">
                              <h1 className="appointmentsHeadingH1" level={4}>
                                {item.doctor_id.salutation.value}{" "}
                                {item.doctor_id.fname} {item.doctor_id.lname}
                              </h1>
                              <div className="docText">
                                <div className="row">
                                  <p className="appointmentsPara1">
                                    {getSpeciality(item.doctor_id.speciality)}
                                  </p>
                                  <div className="col-12 col-sm-12 text-center">
                                    <ul className="appointmentsList">
                                      <li>
                                        <CalendarOutlined
                                          className="doctorIcons"
                                          twoToneColor="#1F274A"
                                        />{" "}
                                        {new Date(item.date).getUTCDate() +
                                          " " +
                                          new Date(item.date).toLocaleString(
                                            "default",
                                            { month: "short" }
                                          ) +
                                          " " +
                                          new Date(item.date).getFullYear()}
                                      </li>
                                      <li>
                                        <ClockCircleOutlined className="doctorIcons" />{" "}
                                        {timeSlotFormat(item.time_slot_start)}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-12 submitRspn mt-md-4 mb-2">
                                    <Link
                                      to={{
                                        pathname: "/appointment-details",
                                        state: {
                                          Details: { item },
                                          isBooking: true,
                                          doctor: `${item.doctor_id._id}`,
                                        },
                                      }}
                                      className="appointmentsBtn-carousel"
                                    >
                                      View Appointment
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
            <div className="carousel carouselWWrap d-none">
              <Carousel
                showIndicators
                showControls
                data-interval="false"
                className="carousel"
              >
                {props.appointments.map((item) => (
                  <Carousel.Item class="carousel-item">
                    <div class="container">
                      <DoctorContainer>
                        <div className="row">
                          <div className="col-6">
                            <img
                              style={{ width: "100%", maxHeight: "250px" }}
                              class="img-fluid svf"
                              src={
                                item.doctor_id.profile_picture
                                  ? `${config.api.s3_url}doctor/${item.doctor_id.profile_picture}`
                                  : item.doctor_id.gender.toLowerCase() ==
                                    "male"
                                  ? `/images/avatar_male.svg`
                                  : item.doctor_id.gender.toLowerCase() ==
                                    "female"
                                  ? `/images/avatar_female.svg`
                                  : `/images/avatar_transgender.svg`
                              }
                              alt=""
                            />
                          </div>
                          <div class="col-6">
                            <div className="doc-info">
                              <DoctorHeadSlide key={item._id} level={3}>
                                {item.doctor_id.salutation.value}{" "}
                                {item.doctor_id.fname} {item.doctor_id.lname}
                              </DoctorHeadSlide>
                              <div className="doc-text">
                                <div className="row">
                                  <div className="col-sm-12 text-center mb-3">
                                    <DoctorParaInline>
                                      {getSpeciality(item.doctor_id.speciality)}
                                    </DoctorParaInline>
                                  </div>
                                  <div className="col-sm-11 col-12">
                                    <IconDiv className="">
                                      <ul>
                                        <li
                                          className="col-6 text-center list"
                                          style={{
                                            fontSize: "18px",
                                            lineHeight: "18px",
                                          }}
                                        >
                                          <CalendarOutlined
                                            className="doctorIcons"
                                            twoToneColor="#1F274A"
                                          />{" "}
                                          {DaySlotFormat(item.date)}
                                        </li>
                                        <li
                                          className="col-6 text-center list"
                                          style={{
                                            fontSize: "18px",
                                            lineHeight: "18px",
                                          }}
                                        >
                                          <ClockCircleOutlined className="doctorIcons" />{" "}
                                          {timeSlotFormat(item.time_slot_start)}
                                        </li>
                                        <li
                                          className="list-mob"
                                          style={{
                                            fontSize: "18px",
                                            lineHeight: "18px",
                                          }}
                                        >
                                          <CalendarOutlined
                                            className="doctorIcons"
                                            twoToneColor="#1F274A"
                                          />{" "}
                                          {DaySlotFormat(item.date)}
                                        </li>
                                        <li
                                          className="list-mob mt-3"
                                          style={{
                                            fontSize: "18px",
                                            lineHeight: "18px",
                                          }}
                                        >
                                          <ClockCircleOutlined className="doctorIcons" />{" "}
                                          {timeSlotFormat(item.time_slot_start)}
                                        </li>
                                      </ul>
                                    </IconDiv>
                                  </div>
                                  <div className="col-sm-12 text-center submitRspn mt-4">
                                    <Link
                                      to={{
                                        pathname: "/appointment-details",
                                        state: {
                                          Details: { item },
                                          isBooking: true,
                                          doctor: `${item.doctor_id._id}`,
                                        },
                                      }}
                                      className="invisiLink"
                                    >
                                      <XButton1
                                        className="themeBtn mt-3"
                                        shape="round"
                                        type="primary"
                                      >
                                        <XText>View Appointment</XText>
                                      </XButton1>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </DoctorContainer>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </>
        ) : (
          <div className="row text-center">
            <div className="col-12">
              <div className="no-appointment">
                <img
                  src={noAppointment}
                  alt="Appointment calendar"
                  className="no-appointment-img"
                />
                <div className="no-appointment-heading">
                  <h3 style={{ color: "gray" }}>No Upcoming</h3>
                  <h3 style={{ color: "gray" }}>Appointments</h3>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  // console.log(articleData,"ad");
  return (
    <>
      <HomeWrap>
        <TopContainer>
          <img
            className="align-center mobile-height"
            src="./images/lilac-insights-logo.svg"
            alt="exp"
          />
        </TopContainer>
        <section id="home">
          <HeaderAlign className="container">
            <div className="row">
              <div
                className={`col-sm-6 col-5 consv ${
                  gender == "male" && "hero-left"
                }`}
              >
                <UserInfo>
                  <p className="username">
                    Hello, <span className="username-text">{firstName}</span>
                  </p>
                  {gender == "female" &&
                  conceivedStatus == "Already Conceived" &&
                  week > 0 ? (
                    <>
                      <p>
                        You are{" "}
                        <b>
                          {patientWeek} {patientWeek < 2 ? "week" : "weeks"}
                        </b>{" "}
                        Pregnant
                      </p>
                    </>
                  ) : null}
                </UserInfo>

                <SocialButton>
                  <Link
                    to={{
                      pathname:
                        "https://play.google.com/store/apps/details?id=com.lilacinsights.healthcare",
                    }}
                    target="_blank"
                  >
                    <GoogleStoreImg
                      className="img-fluid svapp"
                      src="./images/Badge.svg"
                      alt="Google play store"
                    />
                  </Link>
                  <Link
                    to={{ pathname: "https://apps.apple.com/in/app/" }}
                    target="_blank"
                  >
                    <AppleStoreImg
                      className="img-fluid svapp"
                      src="./images/Badge1.svg"
                      alt="Apple Store"
                    />
                  </Link>
                </SocialButton>
              </div>
              <div className="col-sm-6 col-7 consv">
                {/* className="img-fluid homeTopBanner"  //   className="img-fluid homeTopBannerMobile" */}
                {gender == "female" ? (
                  <WomanImg
                    src="./images/group.svg"
                    alt="pregnant-woman"
                    className="woman-animation"
                  />
                ) : (
                  <ManImg src={family} alt="family" className="man-animation" />
                )}
                {/* <WomanImgMobile  src="./images/svg/group-mobile.svg" alt="no" /> */}
              </div>
            </div>
          </HeaderAlign>

          {gender == "female" &&
          conceivedStatus == "Already Conceived" &&
          interest.includes("Pregnancy & Reproductive Wellness") &&
          week > 0 ? (
            <>
              <HomePregaCurveBg>
                <HomeCorveData>
                  <div className="col">
                    <HomeWeekTitie class="row">
                      <span style={{ fontWeight: "600" }}>
                        {patientWeek < (pageWeek === 0 ? 40 : pageWeek)
                          ? "Future week"
                          : patientWeek > (pageWeek === 0 ? 40 : pageWeek)
                          ? "Past week"
                          : "Current week"}
                      </span>
                      <span className="week-font">
                        {pageWeek === 0 ? 40 : pageWeek}{" "}
                        {(pageWeek === 0 ? 40 : pageWeek) < 2
                          ? "week"
                          : "weeks"}
                      </span>
                    </HomeWeekTitie>
                  </div>
                  <div className="h-25 App">
                    <XSlider {...settings}>
                      {images.map((img, idx) => {
                        return idx === imageIndex - 1 ||
                          idx === imageIndex + 1 ? (
                          <BabySliderDiv className="slider slider-2">
                            <img src={img} alt="" />
                          </BabySliderDiv>
                        ) : (
                          <BabySliderDiv
                            className={
                              idx === imageIndex
                                ? `${
                                    idx === page_week
                                      ? "slider slider-page pageWeek"
                                      : "slider activeSlide"
                                  }`
                                : "slider3"
                            }
                          >
                            <img src={img} key={imageIndex} alt="" />
                          </BabySliderDiv>
                        );
                      })}
                    </XSlider>
                  </div>
                  <div className="container">
                    <div className="row galaxyFoldFont">
                      <div className="col-sm-3 col-6">
                        <HomeCardPrgaImg>
                          <img
                            className="img-fluid cardParaImg"
                            src={
                              process.env.PUBLIC_URL + "/images/days_left.svg"
                            }
                            alt="no"
                          />
                        </HomeCardPrgaImg>
                        <HomeCardPrgaPara>
                          <b className="ftext" level={1}>
                            {weekData.days_left}
                          </b>
                          <span>
                            {weekData.days_left < 2 ? "Day" : "Days"} Left
                          </span>
                        </HomeCardPrgaPara>
                      </div>
                      <div className="col-sm-3 col-6">
                        <HomeCardPrgaImg>
                          <img
                            className="img-fluid cardParaImg"
                            src="./images/inch.svg"
                            alt="no"
                          />
                        </HomeCardPrgaImg>
                        <HomeCardPrgaPara>
                          <span>Height</span>
                          <b className="ftext" level={1}>
                            {parseFloat(weekData.height).toFixed(2)}{" "}
                            <span>
                              {/* {parseFloat(weekData.height).toFixed(2) < 2
                              ? "inch"
                              : "inches"} */}
                              inches
                            </span>
                          </b>
                        </HomeCardPrgaPara>
                      </div>
                      <div className="col-sm-3 col-6">
                        <HomeCardPrgaImg>
                          <img
                            className="img-fluid cardParaImg"
                            src="./images/pound.svg"
                            alt="no"
                          />
                        </HomeCardPrgaImg>
                        <HomeCardPrgaPara>
                          <span>Weight</span>
                          <b className="ftext" level={1}>
                            {weekData.weight}{" "}
                            <span>
                              {/* {weekData.weight < 2 ? "gram" : "grams"} */}
                              grams
                            </span>
                          </b>
                        </HomeCardPrgaPara>
                      </div>
                      <div className="col-sm-3 col-6">
                        <HomeCardPrgaImg className="homeBabyIcon">
                          <img
                            className="img-fluid cardParaImg mb-lg-3"
                            src={
                              "https://lilac-dtrf-attactments.s3.ap-south-1.amazonaws.com/pregnancy-data/" +
                              weekData.size_img
                            }
                            alt="no"
                          />
                        </HomeCardPrgaImg>
                        <HomeCardPrgaPara className="mt-lg-4">
                          <span>{weekData.size}</span>
                        </HomeCardPrgaPara>
                      </div>
                    </div>
                  </div>
                </HomeCorveData>
              </HomePregaCurveBg>
            </>
          ) : null}

          <HomeSmilyWrap>
            {/* <MoodOMeter /> */}
            <Appointments appointments={appointments} />

            {gender == "female" &&
              getLmpDate &&
              conceivedStatus == "Already Conceived" &&
              week > 0 && (
                <>
                  <WeeklyHeWrap className="weeklymob">
                    <WeeklyHeWrapBg
                      className=" hideMobile"
                      src="./images/Mask.svg"
                      alt="no"
                    />
                    <div className="container">
                      <div className="row mb-5">
                        <div className="col-sm-12 col-12">
                          <PregaHeading className="heading-home text-center">
                            Weekly Highlights
                          </PregaHeading>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4 col-12 col-lg-4 offset-lg-1 text-sm-center mb-5 mb-lg-0">
                          <MomBabyImg
                            className="img-fluid mom-animation"
                            src="images/mom.svg"
                            alt="no"
                          />
                        </div>
                        <div className="col-sm-8 col-12 col-lg-7">
                          <BabyRadio
                            type="radio"
                            name="baby-mom"
                            id="baby-radio"
                            checked={activeCard === "baby"}
                          ></BabyRadio>
                          <MomRadio
                            type="radio"
                            name="baby-mom"
                            id="mom-radio"
                            checked={activeCard === "mom"}
                          ></MomRadio>
                          <Selector className="selector">
                            <MomBabyCard
                              for="mom-radio"
                              $mode="mom-card"
                              className="mom-card"
                              onClick={handleToggle}
                            >
                              <div className="mom-header">
                                <HomeBabyHeading2>Mom</HomeBabyHeading2>
                                <RightCircleOutlined
                                  className="baby-right-arrow"
                                  onClick={handleRightArrowClick}
                                />
                              </div>
                              <HomeBabySubHeading2>
                                {weekData.description_for_mom ?? ""}
                              </HomeBabySubHeading2>
                              <WeeklyList className="weeklyList2">
                                {weekData.highlights_of_the_week_mom.map(
                                  (text) => (
                                    <li>{text}</li>
                                  )
                                )}
                              </WeeklyList>
                              {weekData.precautions_of_the_week_mom
                                .length[0] && (
                                <>
                                  <HomeBabySubHeading2>
                                    Precautions
                                  </HomeBabySubHeading2>
                                  <WeeklyList className="weeklyList2">
                                    {weekData.precautions_of_the_week_mom.map(
                                      (text) => (
                                        <li>{text}</li>
                                      )
                                    )}
                                  </WeeklyList>
                                </>
                              )}

                              {weekData
                                .recommendation_for_you_of_the_week_mom[0] && (
                                <>
                                  <HomeBabySubHeading2>
                                    Recommendations For You
                                  </HomeBabySubHeading2>
                                  <WeeklyList className="weeklyList2">
                                    {weekData.recommendation_for_you_of_the_week_mom.map(
                                      (text) => (
                                        <li>{text}</li>
                                      )
                                    )}
                                  </WeeklyList>
                                </>
                              )}
                            </MomBabyCard>
                            <MomBabyCard
                              for="baby-radio"
                              $mode="baby-card"
                              className="baby-card"
                              onClick={handleToggle}
                            >
                              <div className="baby-header">
                                <HomeBabyHeading>Baby</HomeBabyHeading>
                                <LeftCircleOutlined
                                  className="baby-left-arrow"
                                  onClick={handleLeftArrowClick}
                                />
                              </div>
                              <HomeBabySubHeading>
                                {weekData.description_for_baby ?? ""}
                              </HomeBabySubHeading>
                              <WeeklyList>
                                {weekData.highlights_of_the_week_baby.map(
                                  (text) => (
                                    <li>
                                      {text.replace(
                                        "###patientName###",
                                        name + "!"
                                      )}
                                    </li>
                                  )
                                )}
                              </WeeklyList>
                              {weekData.precautions_of_the_week_baby[0] && (
                                <>
                                  <HomeBabySubHeading>
                                    Precautions
                                  </HomeBabySubHeading>
                                  <WeeklyList>
                                    {weekData.precautions_of_the_week_baby.map(
                                      (text) => (
                                        <li>{text}</li>
                                      )
                                    )}
                                  </WeeklyList>
                                </>
                              )}

                              {weekData
                                .recommendation_for_you_of_the_week_baby[0] && (
                                <>
                                  <HomeBabySubHeading>
                                    Recommendations For You
                                  </HomeBabySubHeading>
                                  <WeeklyList>
                                    {weekData.recommendation_for_you_of_the_week_baby.map(
                                      (text) => (
                                        <li>{text}</li>
                                      )
                                    )}
                                  </WeeklyList>
                                </>
                              )}
                            </MomBabyCard>
                          </Selector>
                        </div>
                      </div>
                    </div>
                  </WeeklyHeWrap>
                </>
              )}
          </HomeSmilyWrap>

          <div className="clearfix">&nbsp;</div>
          {/* {articleData !== "" ? (
            <WeeklyHeWrap2>
              <img
                src="./images/whitecurve.svg"
                alt="no"
                class="weeklyHeWrapBg"
              ></img>
              <img
                alt="right-bg"
                class="homeRightBg hideMobile"
                src="./images/svg/doctorbgRight.svg"
              />
              <img
                className="yourHelthImg2 hideMobile"
                src="./images/svg/home-helth-bg.svg"
                alt="no"
              />

              <div className="container">
                <div className="Articlecontainer">
                  <div className="mobileHomeWrap">
                    <div className="row">
                      <div className="col-12">
                        <h1 className="curatedforyou mb-0">Curated For You</h1>
                      </div>
                      <div className="col-12 d-none">
                        <div className="homecard">
                          <p className="homePara2">
                            Get support from AtEase experts for mental health
                            issues related to reproductive health like irregular
                            menstruation, PMS (premenstrual syndrome), PMDD
                            (premenstrual depressive disorder) , infertility,
                            PCOS (polycystic ovary syndrome), menopause.
                          </p>
                        </div>
                      </div>
                    </div>
                    {articleData.text.length > 0 && (
                      <div className="row mt-4">
                        <div className="col-12 col-sm-12">
                          <div className="homecard">
                            <div className="row">
                              <div className="col-12 col-sm-3 mb-3 mb-sm-0">
                                <img
                                  class="img-fluid ims"
                                  src={
                                    articleData.text.length > 0
                                      ? `${config.api.s3_url}article/${articleData.text[0].image_path}`
                                      : `./images/mom1.svg`
                                  }
                                  alt="no"
                                ></img>
                              </div>
                              <div className="col-12 col-sm-9">
                                <h5 className="homeCardHeading font-weight-bold mb-1">
                                  {articleData.text.length > 0
                                    ? articleData.text[0].title
                                    : "Guide shows you how to take care of your Baby"}
                                </h5>
                                <p className="mb-4">
                                  {articleData.text.length > 0
                                    ? articleData.text[0].short_description.slice(
                                        0,
                                        100
                                      ) + "..."
                                    : ""}
                                </p>
                                <Link
                                  style={{ textDecoration: "none" }}
                                  className="homeCardReadMore"
                                  to={{
                                    pathname:
                                      "/article/" + articleData.text[0].slug,
                                    state: {
                                      type: "text",
                                      category:
                                        interest.length > 0 &&
                                        interest[0] != "null"
                                          ? interest
                                          : null,
                                      week: imageIndex,
                                    },
                                  }}
                                >
                                  read more
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="text-center mt-3">
                      <Link
                        className="redm"
                        to={{
                          pathname: `/ArticleList`,
                          state: {
                            type: "text",
                            category:
                              interest.length > 0 && interest[1] != "null"
                                ? interest
                                : null,
                            week: imageIndex,
                            week: imageIndex,
                          },
                        }}
                      >
                        View All
                      </Link>
                    </div>
                    
                    
                     <div className="row mt-4">
                      <div className="col-12">
                        <h1 className="curatedforyou mb-4">
                          {gender == "female" &&
                          interest.includes(
                            "Pregnancy & Reproductive Wellness"
                          ) &&
                          conceivedStatus == "Already Conceived"
                            ? "Videos for the week"
                            : "Videos"}
                        </h1>
                      </div>
                      <div className="col-12 d-none">
                        <div className="homecard">
                          <p className="homePara2">
                            Having a mental health condition is as normal as
                            having a physical health condition.
                          </p>
                        </div>
                      </div>
                    </div> 
                    {articleData.video.map(
                      (item, index) =>
                        index <= 2 && (
                          <div className="row mt-4">
                            <div className="col-12 col-sm-12">
                              <div className="homecard" key={item._id}>
                                <div className="row">
                                  <div className="col-12 col-sm-3 mb-3 mb-sm-0">
                                    <img
                                      class="img-fluid ims"
                                      src={
                                        articleData.video.length > 0
                                          ? `${config.api.s3_url}article/${item.image_path}`
                                          : `./images/mom1.svg`
                                      }
                                      alt="no"
                                    ></img>
                                  </div>
                                  <div className="col-12 col-sm-9">
                                    <h5 className="homeCardHeading font-weight-bold mb-1">
                                      {articleData.video.length > 0
                                        ? item.title
                                        : "Guide shows you how to take care of your Baby"}
                                    </h5>
                                    <p>
                                      {articleData.video.length > 0
                                        ? item.short_description.slice(0, 100) +
                                          "..."
                                        : ""}
                                    </p>
                                    <Link
                                      style={{ textDecoration: "none" }}
                                      className="homeCardReadMore"
                                      to={{
                                        pathname: "/article/" + item.slug,
                                        state: {
                                          type: "video",
                                          category:
                                            interest.length > 0 &&
                                            interest[0] != "null"
                                              ? interest
                                              : null,
                                          week: imageIndex,
                                        },
                                      }}
                                    >
                                      read more
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                     <div className="text-center mt-3">
                      <Link
                        className="redm"
                        to={{
                          pathname: `/ArticleList`,
                          state: {
                            type: "video",
                            category:
                              interest.length > 0 && interest[1] != "null"
                                ? interest
                                : null,
                            week: imageIndex,
                            week: imageIndex,
                          },
                        }}
                      >
                        View All
                      </Link>
                    </div> 
                    
                     <div className="row mt-5">
                    <div className="col-12">
                      <h1 className="curatedforyou mb-4">
                        {gender == "female" &&
                        interest.includes(
                          "Pregnancy & Reproductive Wellness"
                        ) &&
                        conceivedStatus == "Already Conceived"
                          ? "Audios for the week"
                          : "Audios"}
                      </h1>
                    </div>
                    <div className="col-12">
                      <div className="homecard">
                        <p className="homePara2">
                          Get support from AtEase experts for mental health
                          issues related to reproductive health like irregular
                          menstruation, PMS (premenstrual syndrome), PMDD
                          (premenstrual depressive disorder) , infertility, PCOS
                          (polycystic ovary syndrome), menopause.
                        </p>
                      </div>
                    </div>
                  </div>
                  {articleData.audio.length > 0 && (
                    <div className="row mt-4">
                      <div className="col-12 col-sm-12">
                        <div className="homecard">
                          <div className="row">
                            <div className="col-12 col-sm-3">
                              <img
                                class="img-fluid ims mb-3 mb-sm-0"
                                src={
                                  articleData.audio.length > 0
                                    ? `https://lilac-dtrf-attactments.s3.ap-south-1.amazonaws.com/article/${articleData.audio[0].image_path}`
                                    : `./images/mom1.svg`
                                }
                                alt="no"
                              ></img>
                            </div>
                            <div className="col-12 col-sm-9">
                              <h5 className="homeCardHeading font-weight-bold mb-1">
                                {articleData.audio.length > 0
                                  ? articleData.audio[0].title
                                  : "Guide shows you how to take care of your Baby"}
                              </h5>
                              <p>
                                {articleData.audio.length > 0
                                  ? articleData.audio[0].short_description.slice(
                                      0,
                                      100
                                    ) + "..."
                                  : ""}
                              </p>
                              <Link
                                style={{ textDecoration: "none" }}
                                className="homeCardReadMore"
                                to={{
                                  pathname:
                                    "/ArticleList/listen/" +
                                    articleData.audio[0].slug,
                                  state: {
                                    type: "audio",
                                    category:
                                      interest.length > 0 &&
                                      interest[0] != "null"
                                        ? interest
                                        : null,
                                    week: imageIndex,
                                  },
                                }}
                              >
                                read more
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {articleData.audio.length > 1 && (
                    <div className="row mt-4">
                      <div className="col-12 col-sm-12">
                        <div className="homecard">
                          <div className="row">
                            <div className="col-4 col-sm-3">
                              <img
                                class="img-fluid ims"
                                src={
                                  articleData.audio.length > 0
                                    ? `https://lilac-dtrf-attactments.s3.ap-south-1.amazonaws.com/article/${articleData.audio[1].image_path}`
                                    : `./images/mom1.svg`
                                }
                                alt="no"
                              ></img>
                            </div>
                            <div className="col-8 col-sm-9">
                              <h5 className="homeCardHeading font-weight-bold mb-1">
                                {articleData.audio.length > 0
                                  ? articleData.audio[1].title
                                  : "Guide shows you how to take care of your Baby"}
                              </h5>
                              <p>
                                {articleData.audio.length > 0
                                  ? articleData.audio[1].short_description.slice(
                                      0,
                                      100
                                    ) + "..."
                                  : ""}
                              </p>
                              <Link
                                style={{ textDecoration: "none" }}
                                className="homeCardReadMore"
                                to={{
                                  pathname:
                                    "/ArticleList/listen/" +
                                    articleData.audio[1].slug,
                                  state: {
                                    type: "audio",
                                    category:
                                      interest.length > 0 &&
                                      interest[1] != "null"
                                        ? interest
                                        : null,
                                    week: imageIndex,
                                  },
                                }}
                              >
                                read more
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="text-center mt-3">
                    <Link
                      className="redm"
                      to={{
                        pathname: `/ArticleList`,
                        state: {
                          type: "audio",
                          category:
                            interest.length > 0 && interest[1] != "null"
                              ? interest
                              : null,
                          week: imageIndex,
                          week: imageIndex,
                        },
                      }}
                    >
                      View More
                    </Link>
                  </div> 
                    
                  </div>

                   <div className="hideMobile"> 
                  {articleData.text.length > 0 ? (
                      <ArticleBlock
                        align="right"
                        title="Curated For You"
                        week={imageIndex}
                        type="text"
                        link="ArticleList/read"
                        apiType="text"
                        data={articleData.text}
                        interest={
                          interest.length > 0 && interest[0] != "null"
                            ? interest
                            : null
                        }
                      />
                    ) : null}
                   {articleData.video.length > 0 ? (
                      <ArticleBlock
                        align={articleData.text.length > 0 ? "left" : "right"}
                        title={
                          gender == "female" &&
                          interest.includes(
                            "Pregnancy & Reproductive Wellness"
                          ) &&
                          conceivedStatus == "Already Conceived"
                            ? "Videos for the week"
                            : "Videos"
                        }
                        week={imageIndex}
                        type="video"
                        link="ArticleList/watch"
                        apiType="video"
                        data={articleData.video}
                        interest={
                          interest.length > 0 && interest[0] != "null"
                            ? interest
                            : null
                        }
                      />
                    ) : null} 
                   {articleData.audio.length > 0 ? (
                    <ArticleBlock
                      apiType="audio"
                      align={articleData.video.length > 0 ? "right" : "left"}
                      title={
                        gender == "female" &&
                        interest.includes(
                          "Pregnancy & Reproductive Wellness"
                        ) &&
                        conceivedStatus == "Already Conceived"
                          ? "Audios for the week"
                          : "Audios"
                      }
                      week={imageIndex}
                      type="audio"
                      link="ArticleList/listen"
                      data={articleData.audio}
                      interest={
                        interest.length > 0 && interest[0] != "null"
                          ? interest
                          : null
                      }
                    />
                  ) : null} 
                   </div> 
                </div>
              </div>
            </WeeklyHeWrap2>
          ) : null} */}
        </section>

        <section id="health">
          <HealthBlock />
        </section>

        <section id="ease">
          {/* requested by lilac */}
          {/* <AtEasy openDrawer={showDrawer} closeDrawer={onClose} /> */}
        </section>
        <LilacInsightsServices serviceList={serviceList} />
        {/* requested by lilac */}

        {/* <Resource week={imageIndex} /> */}
        <Drawer
          title="Chat With Sahej"
          placement="right"
          onClose={onClose}
          visible={visible}
          width={drawerWidth}
          afterVisibleChange={(visible) => {
            if (visible) {
              document.querySelector(".message-area").scrollTo(0, 9999);
            }
          }}
        >
          <div className="message-area" ref={myRef}>
            <ul>
              {loadingChat ? (
                <CustomLoader />
              ) : recievedMsg.length > 0 ? (
                <>
                  {recievedMsg.map((msg, index) => {
                    {
                      return msg.align === "left" ? (
                        <li key={index}>
                          {/* <div className="user-pic">  */}
                          <img
                            className="img-fluid"
                            src="./images/panda.svg"
                            alt="no"
                          />
                          {/* </div> */}
                          <div className="message-text">
                            <>{msg.text}</>
                          </div>
                        </li>
                      ) : (
                        <li
                          key={index}
                          style={{ flexDirection: "row-reverse" }}
                        >
                          <div className="sentMessage-text">
                            <>
                              <section id={msg.text}>{msg.text}</section>
                            </>
                          </div>
                        </li>
                      );
                    }
                  })}
                </>
              ) : (
                <></>
              )}
              {/* <li>
                <img
                  className="img-fluid"
                  src="./images/panda.svg"
                  alt="no"
                />
                <Spin size="middle" style={{marginLeft: "10px" ,alignSelf: "end"}}></Spin>
              </li>         */}
            </ul>
          </div>
          <hr />
          <form onSubmit={sendMesage} className="message-control">
            <textarea
              value={sendMessage}
              onChange={(e) => setSendMessage(e.target.value)}
              placeholder="Type something..."
            />
            <div className="file-input-container">
              {/* <input type="file" id="hidden-file" onChange={(e) => {
                            const file = e.target.files[0];
                            console.log(file)
                        }} />
                        <lable htmlfor="hidden-file"><PaperClipOutlined  className="mr-2"  style={{fontSize:"20px"}} /></lable>  */}
            </div>
            <Button
              type="primary"
              icon={<SendOutlined />}
              shape="round"
              htmlType="submit"
            >
              Send
            </Button>
          </form>
        </Drawer>
        {/* requested by lilac */}
        {/* <div className="row">
          <YourHelthBg className="pb-0">
            <div className="container">
              <div className="row">
                <div className="offset-sm-1 col-sm-10 col-12 text-center tab-margin">
                  {tribe.length === 0 ? (
                    <>
                      <Button
                        className="btn modalLinkBtn"
                        type="link"
                        onClick={showModal}
                        style={{ height: "fit-content" }}
                      >
                        <img
                          className="img-fluid"
                          src="./images/svg/homeModalLinkBg.svg"
                          alt="no_img"
                        />
                      </Button>
                      <TribeModal
                        visible={isModalVisible}
                        onClose={() => setIsModalVisible(false)}
                      />
                    </>
                  ) : (
                    <>
                      <Link to="/support-tribe">
                        <button
                          className="btn modalLinkBtn"
                          type="link"
                          style={{ height: "fit-content" }}
                        >
                          <img
                            className="img-fluid"
                            src="./images/svg/homeModalLinkBg.svg"
                            alt="no_img"
                          />
                        </button>
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </YourHelthBg>
        </div> */}

        <div className="container mt-5">
          {Testimonials.length > 0 && (
            <>
              <div className="row">
                <div className="col-12">
                  <YourHelthBg>
                    <CarouselBlock Testimonials={Testimonials} />
                  </YourHelthBg>
                </div>
              </div>
            </>
          )}
        </div>
      </HomeWrap>
      {scrollTopBtn && <ScrollToTop />}
    </>
  );
};

const mapStateToProps = (state) => ({
  getPregHighlightAppListState: state.getPregHighlightAppList,
  loginState: state.login,
  getChatBotState: state.getChatBot,
  getArticleAppListState: state.getArticleAppList,
  getServicesListState: state.getServicesList,
});

const mapDispatchToProps = (dispatch) => ({
  login: (params) => dispatch(login(params)),
  getPregHighlightAppList: (params) =>
    dispatch(getPregHighlightAppList(params)),
  getPregHighlightAppListReset: () => dispatch(getPregHighlightAppListReset()),
  getChatBot: (params) => dispatch(getChatBot(params)),
  getChatBotReset: () => dispatch(getChatBotReset()),
  getArticleAppList: (params) => dispatch(getArticleAppList(params)),
  getArticleAppListReset: () => dispatch(getArticleAppListReset()),
  getServicesList: (params) => dispatch(getServicesList(params)),
  getServicesListReset: () => dispatch(getServicesListReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
