import { React, useState, useEffect } from "react";
import { Typography, Button, Modal, Tabs } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  getDoctorDetail,
  getDoctorDetailReset,
} from "../../action/getDoctorDetailAction";
import { connect } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { timeSlotFormat, DaySlotFormat } from "../../utils";
import moment from "moment";
import { DoctorDetailBlock } from "../../component/DoctorDetailBlock/DoctorDetailBlock";
import config from "../../config";
import axios from "axios";
import { getPatientToken } from "../../utils";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

//STYLE
import "./DoctorDetail.css";
import {
  DrDetailsRightHead,
  TopContainer,
  PageWrap,
} from "./DoctorDetailStyle";
import ScrollToTop from "../../component/ScrollToTop/ScrollToTop";

function DoctorDetail(props) {
  const [firstTabData, setFirstTabData] = useState([]);
  const [firstTabDatas, setFirstTabDatas] = useState([]);
  const [firstTabs, setFirstTabs] = useState([]);
  const [secondTabData, setSecondTabData] = useState([]);
  const [linkTabData, setLinkTabData] = useState([]);
  const { TabPane } = Tabs;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalSharedVisible, setIsModalSharedVisible] = useState(false);
  const { Title } = Typography;
  const { getDoctorDetail, getDoctorDetailReset, getDoctorDetailState } = props;
  const [isDisabled, setisDisabled] = useState(false);
  const [isLoadlDisabled, setisDisabledLoad] = useState(false);
  const [bookingData, setBookingData] = useState([]);
  const location = useLocation();
  const isBooking = location.state.isBooking;
  const doctor = location.state.doctor;
  const type = location.state.type;
  const Details = location.state.Details;
  const dat = new Date();
  const [Detailsitem, setDetailsitem] = useState([]);
  const [reportdata, setreportdata] = useState();
  const [scrollTopBtn, setScrollTopBtn] = useState(false);
  const doctorParams = {
    doctor: doctor,
  };
  function closeModal() {
    setIsModalVisible(false);
    setIsModalSharedVisible(false);
  }
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {};
  const repo = () => {
    setIsModalSharedVisible(true);
    let uniquedata = [];
    Detailsitem[0].report.map((it) => {
      let data = firstTabData.result?.filter((item) => item._id === it._id);

      uniquedata.push(data);
    });

    setFirstTabs(...uniquedata);

    console.log("data", ...uniquedata);
  };
  const [response, setResponse] = useState();
  const [doctorData, setDoctorData] = useState({
    id: "",
    speciality: "",
    fname: "",
    lname: "",
    qualification: "",
    profile_picture: "",
    description: "",
    tier_1_fee: "",
    short_description: "",
    tier_2_fee: "",
    self_fee: "",
    couple_fee: "",
    family_fee: "",
    medicine_fee: "",
    exprience: "",
    salutation: "",
    clinic_address_line1: "",
    clinic_address_line2: "",
    clinic_city: "",
    clinic_country: "",
    clinic_postal_code: "",
    clinic_state: "",
    country: "",
    mobile_number: "",
    type: "",
    customer_support: "",
  });
  // console.log(Details.item)
  // console.log("doctorData",doctorData)

  function JoinTime() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    var time = today.getHours() + ":" + today.getMinutes();
    today = mm + "/" + dd + "/" + yyyy;

    // console.log(today,time);

    const date = moment(Details.item.date).format("MM/DD/YYYY");
    const ApptTime = moment(Details.item.time_slot_start, "HH:mm:ss").format(
      "HH:MM"
    );

    // console.log(date, ApptTime)
    if (time > ApptTime) {
      setisDisabled(false);
    } else {
      setisDisabled(true);
    }
  }

  // const x = JoinTime();
  function getAge(dateString) {
    var today = new Date();
    var expDate = new Date(dateString);
    var experience = today.getFullYear() - expDate.getFullYear();
    var m = today.getMonth() - expDate.getMonth();
    //
    if (m < 0 || (m === 0 && today.getDate() < expDate.getDate())) {
      experience--;
    }
    return experience;
  }
  let QuaValues = [];
  let LangValues = [];
  const date = new Date(doctorData.exprience).toLocaleDateString("us");
  if (doctorData?.speciality) {
    doctorData.speciality.forEach((element, index) => {
      if (element && element.value) {
        QuaValues[index] = " " + element?.value;
      }
    });
    // for (let i = 0; i < doctorData.speciality.length; i++) {
    //   QuaValues[i] = " " + doctorData.speciality[i].value + " ";
    // }
  }
  if (doctorData?.speciality) {
    doctorData.language.forEach((element, index) => {
      if (element && element.value) {
        LangValues[index] = " " + element?.value;
      }
    });
    // for (let i = 0; i < doctorData.language.length; i++) {
    //   LangValues[i] = " " + doctorData.language[i].value + " ";
    // }
  }
  function getSpeciality(spec) {
    let QuaValues = [];
    for (let i = 0; i < spec.length; i++) {
      QuaValues[i] = spec[i].value;
    }
    return QuaValues.join(", ");
  }

  function getCancel() {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure want to cancel booked appointment.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            //alert('1');
            if (getDoctorDetailState.data.data.data._id !== "") {
              const cancleID = getDoctorDetailState.data.data.data._id;
              //alert(cancleID);
              setisDisabledLoad(true);
              const bookingParams = {
                book_appointment_id: cancleID,
              };
              axios({
                url: `${config.api.base_url}book-appointment/cancel-book-appointment`,
                method: "post",
                data: bookingParams,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${getPatientToken()}`,
                },
              }).catch(function (error) {
                if (error.response.data.status === 0) {
                  setisDisabledLoad(false);
                }
              });
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            //alert('2')
          },
        },
      ],
    });
  }

  //CALLBACKS
  useEffect(() => {
    window.scrollTo(0, 0);
    getDoctorDetail(doctorParams);
    if (isBooking) {
      JoinTime();
    }
    return () => {
      getDoctorDetailReset();
    };
  }, []);

  useEffect(() => {
    if (getDoctorDetailState.apiState === "success") {
      // console.log(getDoctorDetailState.data.data)
      console.log("isBooking", getDoctorDetailState.data.data.data);
      setDoctorData({
        ["id"]: getDoctorDetailState.data.data.data._id,
        ["speciality"]: getDoctorDetailState.data.data.data.speciality,
        ["fname"]: getDoctorDetailState.data.data.data.fname,
        ["lname"]: getDoctorDetailState.data.data.data.lname,
        ["gender"]: getDoctorDetailState.data.data.data.gender,
        ["qualification"]: getDoctorDetailState.data.data.data.qualification,
        ["profile_picture"]:
          getDoctorDetailState.data.data.data.profile_picture,
        ["description"]: getDoctorDetailState.data.data.data.description,
        ["tier_1_fee"]: getDoctorDetailState.data.data.data.tier_1_fee,
        ["tier_2_fee"]: getDoctorDetailState.data.data.data.tier_2_fee,
        ["self_fee"]: getDoctorDetailState.data.data.data.self_fee,
        ["couple_fee"]: getDoctorDetailState.data.data.data.couple_fee,
        ["family_fee"]: getDoctorDetailState.data.data.data.family_fee,
        ["medicine_fee"]: getDoctorDetailState.data.data.data.medicine_fee,
        ["short_description"]:
          getDoctorDetailState.data.data.data.short_description,
        ["exprience"]: getDoctorDetailState.data.data.data.exprience,
        ["salutation"]:
          getDoctorDetailState.data.data.data.salutation != null
            ? getDoctorDetailState.data.data.data.salutation.value
            : "",
        ["clinic_address_line1"]:
          getDoctorDetailState.data.data.data.clinic_address_line1,
        ["clinic_address_line2"]:
          getDoctorDetailState.data.data.data.clinic_address_line2,
        ["clinic_city"]: getDoctorDetailState.data.data.data.clinic_city,
        ["clinic_country"]: getDoctorDetailState.data.data.data.clinic_country,
        ["clinic_postal_code"]:
          getDoctorDetailState.data.data.data.clinic_postal_code,
        ["clinic_state"]: getDoctorDetailState.data.data.data.clinic_state,
        ["country"]: getDoctorDetailState.data.data.data.country,
        ["mobile_number"]: getDoctorDetailState.data.data.data.mobile_number,
        ["language"]: getDoctorDetailState.data.data.data.language,
        ["type"]: getDoctorDetailState.data.data.data.type,
        ["customer_support"]:
          getDoctorDetailState.data.data.data.customer_support,
      });
    }
  }, [getDoctorDetailState]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setScrollTopBtn(true);
      } else {
        setScrollTopBtn(false);
      }
    });
  }, []);

  // console.log("language", Details.item.doctor_id.language)
  // useEffect(async () => {
  //   window.scrollTo(0, 0);
  //   let datas = [];
  //   let datasfirst = [];
  //   let datasfinal = [];
  //   console.log("Detailsitem", location.state.Details);
  //   const res = await axios({
  //     url:
  //       `${config.api.base_url}book-appointment/` +
  //       location.state.Details?.item?._id,
  //     method: "get",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${getPatientToken()}`,
  //     },
  //   });
  //   console.log("Response => ",res.data.data.result);
  //   datas.push(res.data.data.result);
  //   setDetailsitem(datas);
  //   console.log("check", datas);
  //   let unique;

  //   Detailsitem.map((item) => {
  //     //  unique = [...new Set(item.report._id)]

  //     datasfirst.push(item.report);
  //   });
  //   setreportdata(datas[0].report);
  //   console.log("resp1", reportdata);
  //   fetch(`${config.api.base_url}report/list`, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${getPatientToken()}`,
  //     },
  //   }).then((result) => {
  //     result.json().then((resp) => {
  //       console.log(resp.data);

  //       setFirstTabData(resp.data);

  //       //console.log(resp)

  //       //console.log(resp.data.result[0].report[0].location)

  //       //console.log(resp.data.result[0].file_path)
  //       //console.log(firstTabData.result[0].file_path)
  //     });
  //   });
  // }, []);

  function getTabKey(key) {
    //console.log(key);
    if (key == 1) {
      firstTab();
    }
    if (key == 2) {
      secondTab();
    }
  }

  function firstTab() {
    //const firstTab = () => {
    fetch(`${config.api.base_url}report/list`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getPatientToken()}`,
      },
    }).then((result) => {
      result.json().then((resp) => {
        console.log(resp.data.file_path);
        setFirstTabData(resp.data);
        //console.log(resp)
        //console.log(resp.data.result[0].report[0].location)
        //console.log(resp.data.result[0].file_path)
        //console.log(firstTabData.result[0].file_path)
      });
    });
  }

  function secondTab() {
    fetch(`${config.api.base_url}report/get-test-reports`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getPatientToken()}`,
      },
    }).then((result) => {
      result.json().then((resp) => {
        setSecondTabData(resp.data);
        setLinkTabData(resp.data.result[0]?.report[0]?.location);
        //console.log(resp.data.result.report)
        //console.log(resp.data.result[0].report[0].location)
      });
    });
  }
  return (
    <div className="container">
      <TopContainer>
        <img
          className="align-center mobile-height"
          src="./images/lilac-insights-logo.svg"
          alt="exp"
        />
        {/* <Title level={1}>Consult</Title> */}
      </TopContainer>
      <img
        alt="right-bg"
        className="doctorRightBg1 hideMobile"
        // style={{ top: "40%" }}
        src="./images/svg/doctorbgRight.svg"
      />
      <img
        alt="right-bg"
        className="doctorLeftBg hideMobile"
        style={{ top: "40%" }}
        src="./images/svg/doctorbgLeft.svg"
      />
      <PageWrap>
        <div className="row">
          <div className="col-sm-12">
            {isBooking ? (
              <h1 className="dtDetailsHead">Appointment Details </h1>
            ) : (
              <h1 className="dtDetailsHead">Expert Details </h1>
            )}
          </div>
        </div>

        <div className="row">
          <div className="row">
            <div className="col-sm-12 col-md-8  col-lg-8 col-12 mb-4 first">
              <DoctorDetailBlock doctorData={doctorData} isExperience={true} />
            </div>
          </div>
          <div className="row"></div>
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-12 mb-4">
            <div className="drDetailsRightBoxWrap ht-100">
              <div className="drDetailsRightBox2">
                <div className="drlistImgRightWrapLeft">
                  <img
                    className="imp-fluid"
                    src="./images/Qdetails.svg"
                    alt=""
                  />
                </div>
                <div className="drlistImgRightWrapRight">
                  <DrDetailsRightHead>Qualification Details</DrDetailsRightHead>
                  <h6 className="drDetailsRightHead2 drDetailsInfo">
                    {doctorData.qualification}
                  </h6>
                </div>
                <div className="clearfix"></div>
              </div>

              <div className="drDetailsRightBox2">
                <div className="drlistImgRightWrapLeft">
                  <img
                    className="imp-fluid"
                    src="./images/specialization.svg"
                    alt=""
                  />
                </div>
                <div className="drlistImgRightWrapRight">
                  <DrDetailsRightHead>Specialisations</DrDetailsRightHead>
                  <h6 className="drDetailsRightHead2 drDetailsInfo">
                    {QuaValues.toString() ?? " "}
                  </h6>
                </div>
                <div className="clearfix"></div>
              </div>

              {/* {isBooking?  */}
              <div className="drDetailsRightBox2">
                <div className="drlistImgRightWrapLeft">
                  <svg
                    className="imp-fluid"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM18.92 8H15.97C15.65 6.75 15.19 5.55 14.59 4.44C16.43 5.07 17.96 6.35 18.92 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4.26 14C4.1 13.36 4 12.69 4 12C4 11.31 4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12C7.5 12.68 7.56 13.34 7.64 14H4.26ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM8.03 8H5.08C6.04 6.34 7.57 5.07 9.41 4.44C8.81 5.55 8.35 6.75 8.03 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM14.34 14H9.66C9.57 13.34 9.5 12.68 9.5 12C9.5 11.32 9.57 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12C14.5 12.68 14.43 13.34 14.34 14ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.36 14C16.44 13.34 16.5 12.68 16.5 12C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12C20 12.69 19.9 13.36 19.74 14H16.36Z"
                      fill="url(#paint0_linear_3850:4296)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_3850:4296"
                        x1="2"
                        y1="2"
                        x2="23.4926"
                        y2="3.75865"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#D487D4" />
                        <stop offset="1" stopColor="#A54EA5" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="drlistImgRightWrapRight">
                  <DrDetailsRightHead>Languages</DrDetailsRightHead>
                  <h6 className="drDetailsRightHead2 drDetailsInfo">
                    {LangValues.toString()}
                  </h6>
                </div>
                <div className="clearfix"></div>
              </div>
              {/* :<></>
                                } */}
            </div>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-12 mb-4">
            <div className="drDetailsRightBoxWrap ht-100">
              {isBooking ? (
                <></>
              ) : (
                <div className="drDetailsRightBox2">
                  <div className="drlistImgRightWrapLeft">
                    <img className="imp-fluid" src="./images/fee.svg" alt="" />
                  </div>
                  <div className="drlistImgRightWrapRight">
                    <DrDetailsRightHead>Consultation Fees</DrDetailsRightHead>
                    {type === "MHC" && (
                      <>
                        {doctorData.tier_1_fee ? (
                          <>
                            <h6 className="drDetailsInfo drFees">
                              First Consultation Fees
                            </h6>
                            <h5 className="drDetailsRightHead2 drDetailsInfo">
                              <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                              {doctorData.tier_1_fee}
                            </h5>
                          </>
                        ) : (
                          <></>
                        )}
                        {doctorData.tier_2_fee ? (
                          <>
                            <h6 className="drDetailsInfo drFees">Follow-up</h6>
                            <h5 className="drDetailsRightHead2 drDetailsInfo">
                              <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                              {doctorData.tier_2_fee}
                            </h5>
                          </>
                        ) : (
                          <></>
                        )}
                        {doctorData.self_fee ? (
                          <>
                            <h6 className="drDetailsInfo drFees">Self Fee</h6>
                            <h5 className="drDetailsRightHead2 drDetailsInfo">
                              <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                              {doctorData.self_fee}
                            </h5>
                          </>
                        ) : (
                          <></>
                        )}
                        {doctorData.family_fee ? (
                          <>
                            <h6 className="drDetailsInfo drFees">Family Fee</h6>
                            <h5 className="drDetailsRightHead2 drDetailsInfo">
                              <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                              {doctorData.family_fee}
                            </h5>
                          </>
                        ) : (
                          <></>
                        )}
                        {doctorData.medicine_fee ? (
                          <>
                            <h6 className="drDetailsInfo drFees">Medicine Fee</h6>
                            <h5 className="drDetailsRightHead2 drDetailsInfo">
                              <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                              {doctorData.medicine_fee}
                            </h5>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                    {type === "GC" && (
                      <>
                        {doctorData.tier_1_fee ? (
                          <>
                            <h6 className="drDetailsInfo drFees">
                              First Consultation Fees
                            </h6>
                            <h5 className="drDetailsRightHead2 drDetailsInfo">
                              <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                              {doctorData.tier_1_fee}
                            </h5>
                          </>
                        ) : (
                          <></>
                        )}
                        {doctorData.tier_2_fee ? (
                          <>
                            <h6 className="drDetailsInfo drFees">Follow-up</h6>
                            <h5 className="drDetailsRightHead2 drDetailsInfo">
                              <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                              {doctorData.tier_2_fee}
                            </h5>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                  <div className="clearfix"></div>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-12 mb-4">
            {isBooking ? (
              <>
                <Link to={`/appointment/meeting${props.id}`}>
                  <Button
                    className="themeBtn w-100 mb-4"
                    size="large"
                    // disabled={isDisabled}
                  >
                    Join
                  </Button>
                </Link>
                <div className="drDetailsRightBoxWrap2">
                  <div className="drDetailsRightBox2 mb-0">
                    <div className="drlistImgRightWrapLeft">
                      <img
                        className="imp-fluid"
                        src="./images/report_id.svg"
                        alt=""
                      />
                    </div>
                    <div className="drlistImgRightWrapRight">
                      <DrDetailsRightHead>
                        <span className="btn p-0" onClick={showModal}>
                          <h5 className="drDetailsRightHead text-center">
                            Order Details{" "}
                          </h5>
                        </span>
                      </DrDetailsRightHead>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>

                <div className="drDetailsRightBoxWrap2">
                  <div className="drDetailsRightBox2 mb-0">
                    <div className="drlistImgRightWrapLeft">
                      <img
                        className="imp-fluid"
                        src="./images/share_report.svg"
                        alt=""
                      />
                    </div>
                    <div className="drlistImgRightWrapRight">
                      <DrDetailsRightHead>
                        <span className="btn p-0" onClick={repo}>
                          <h5 className="drDetailsRightHead text-center">
                            Shared Reports
                          </h5>
                        </span>
                      </DrDetailsRightHead>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
                {new Date(Details.item.date).getDate() >
                new Date(dat.getDate()) ? (
                  <div className="drDetailsRightBoxWrap">
                    <div className="drDetailsRightBox2 mb-0 ">
                      <div className="drlistImgRightWrapLeft">
                        <CloseCircleOutlined
                          style={{ fontSize: "20px", color: "#a54ea5" }}
                        />
                      </div>
                      <button
                        className="btn p-0"
                        onClick={getCancel}
                        disabled={isLoadlDisabled}
                      >
                        <h5 className="drDetailsRightHead">Cancel Booking</h5>
                      </button>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              <Button className="themeBtn w-100 mb-4" size="large">
                <Link
                  to={{
                    pathname: "/doctor-appoint",
                    state: { doctor: doctor, type: doctorData.type },
                  }}
                  className="invisiLink"
                >
                  Book
                </Link>
              </Button>
            )}

            {isBooking ? (
              <></>
            ) : (
              <>
                <Link
                  to={{
                    pathname: "/booking",
                    state: { doctor: doctorData.id, isHistory: false },
                  }}
                >
                  <div className="drDetailsRightBoxWrap2">
                    <div
                      className="drDetailsRightBox2 mb-0"
                      style={{ border: "none", boxShadow: "none" }}
                    >
                      <div className="drlistImgRightWrapLeft">
                        <img
                          className="imp-fluid"
                          src="./images/consult.svg"
                          alt=""
                        />
                      </div>
                      <div className="drlistImgRightWrapRight">
                        <DrDetailsRightHead>
                          Upcoming Consultations
                        </DrDetailsRightHead>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </Link>

                <Link
                  to={{
                    pathname: "/booking",
                    state: { doctor: doctorData.id, isHistory: true },
                  }}
                >
                  <div className="drDetailsRightBoxWrap2">
                    <div
                      className="drDetailsRightBox2 mb-0"
                      style={{ border: "none", boxShadow: "none" }}
                    >
                      <div className="drlistImgRightWrapLeft">
                        <img
                          className="imp-fluid"
                          src="./images/Chistory.svg"
                          alt=""
                        />
                      </div>
                      <div className="drlistImgRightWrapRight">
                        <DrDetailsRightHead>
                          Consultation History
                        </DrDetailsRightHead>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </Link>
              </>
            )}

            {isBooking ? (
              <>
                <div className="drDetailsRightBoxWrap2">
                  <div className="drDetailsRightBox2">
                    <div className="drlistImgRightWrapLeft">
                      <img
                        className="imp-fluid"
                        src="./images/booked_for.svg"
                        alt=""
                      />
                    </div>
                    <div className="drlistImgRightWrapRight">
                      <DrDetailsRightHead>Booked For</DrDetailsRightHead>
                      <h6 className="drDetailsRightHead2">abcd</h6>
                    </div>
                    <div className="clearfix"></div>

                    <div className="drlistImgRightWrapLeft">
                      <img
                        className="imp-fluid"
                        src="./images/message_icon.svg"
                        alt=""
                      />
                    </div>
                    <div className="drlistImgRightWrapRight">
                      <DrDetailsRightHead>Consultation Type</DrDetailsRightHead>
                      <h6 className="drDetailsRightHead2">abcd</h6>
                    </div>
                    <div className="clearfix"></div>

                    <div className="drlistImgRightWrapLeft">
                      <img
                        className="imp-fluid"
                        src="./images/Consultation.svg"
                        alt=""
                      />
                    </div>
                    <div className="drlistImgRightWrapRight">
                      <DrDetailsRightHead>Consultation Date</DrDetailsRightHead>
                      <h6 className="drDetailsRightHead2">
                        {DaySlotFormat(Details.item.date)}
                      </h6>
                    </div>
                    <div className="clearfix"></div>

                    <div className="drlistImgRightWrapLeft">
                      <img
                        className="imp-fluid"
                        src="./images/Chistory.svg"
                        alt=""
                      />
                    </div>
                    <div className="drlistImgRightWrapRight">
                      <DrDetailsRightHead>Total time</DrDetailsRightHead>
                      <h6 className="drDetailsRightHead2">
                        {timeSlotFormat(Details.item.time_slot_start)} -{" "}
                        {timeSlotFormat(Details.item.time_slot_end)}{" "}
                      </h6>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {doctorData.customer_support && (
                  <div
                    className="drDetailsRightBoxWrap2"
                    onClick={() =>
                      window.open(
                        "tel:" + doctorData.customer_support,
                        "_parent"
                      )
                    }
                  >
                    <div className="drDetailsRightBox2 mb-0">
                      <div className="drlistImgRightWrapLeft">
                        <img
                          className="imp-fluid"
                          src="./images/phone.svg"
                          alt=""
                        />
                      </div>
                      <div className="drlistImgRightWrapRight">
                        <DrDetailsRightHead>Contact</DrDetailsRightHead>
                        <h6 className="drDetailsRightHead2 drDetailsInfo">
                          {doctorData.customer_support}
                        </h6>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center mb-5">
            <Modal
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={closeModal}
              footer={null}
            >
              <div className="row">
                <div className="col-12">
                  <b>
                    <h5 style={{ textAlign: "center" }}>Order Details</h5>
                  </b>

                  {Detailsitem ? (
                    <div>
                      {Detailsitem.map((item) => {
                        return (
                          <div>
                            <p>
                              <span className="pr-2">Appointment_no:</span>
                              {item.appointment_no}
                            </p>
                            <p>
                              <span className="pr-2">Amount::</span>
                              {item.amount}
                            </p>
                            <p>
                              <span className="pr-2">Created:</span>
                              {item.createdAt}
                            </p>
                            <p>
                              <span className="pr-2">Expiry_date:</span>
                              {item.expiry_date}
                            </p>
                            {/*   <p><span className="pr-2">Consultation:</span>{item.consultation}</p>*/}
                            <p>
                              <span className="pr-2">Payment method:</span>
                              {item.payment_method}
                            </p>
                            <p>
                              <span className="pr-2">Payment status:</span>
                              {item.payment_status}
                            </p>
                            <p>
                              <span className="pr-2">Referred by:</span>
                              {item.referred_by}
                            </p>
                            <p>
                              <span className="pr-2">Time slot start:</span>
                              {item.time_slot_start}
                            </p>
                            <p>
                              <span className="pr-2">Time slot end:</span>
                              {item.time_slot_end}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </Modal>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center mb-5">
            <Modal
              visible={isModalSharedVisible}
              onCancel={closeModal}
              footer={null}
              width={1000}
            >
              <div className="row">
                <div className="col-12">
                  <b>
                    <h5 style={{ textAlign: "center" }}>Shared Report</h5>
                  </b>
                  <div>
                    <Tabs
                      defaultActiveKey="1"
                      onChange={getTabKey}
                      tabPosition="top"
                      className="reportTabWrap"
                    >
                      <TabPane tab="Your Reports" key="1">
                        <div className="row">
                          {reportdata ? (
                            <>
                              {reportdata.map((item) => (
                                <>
                                  <div className="col-sm-6 col-12 mb-3">
                                    <div className="reportBox">
                                      <h5>
                                        File Name:{" "}
                                        <span style={{ fontWeight: "normal" }}>
                                          {item.file_path}
                                        </span>
                                      </h5>
                                      <p className="text-right mb-0">
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={
                                            `${config.api.s3_url}/` +
                                            item.file_path
                                          }
                                          download
                                        >
                                          <i class="fa fa-download reportDownload"></i>
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </>
                          ) : null}
                        </div>
                      </TabPane>
                      <TabPane tab="Doctor Reports" key="2">
                        <div className="row">
                          {secondTabData.result &&
                          secondTabData.result.length > 0 ? (
                            <>
                              {secondTabData.result.map((item) => (
                                <div className="col-sm-6 col-12 mb-3">
                                  <div className="reportBox">
                                    <div className="row align-items-center">
                                      <div className="col-4 text-center">
                                        {/*
                                                        <img className="img-fluid" style={{height:'240px'}} src={item.report[0].location} />
                                                        */}
                                        <i
                                          style={{
                                            fontSize: "8rem",
                                            color: "#CCC",
                                          }}
                                          class="fa fa-files-o"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                      <div className="col-8">
                                        <p>
                                          <strong>Patient Name: </strong>{" "}
                                          {item.patient[0].name.firstName}{" "}
                                          {item.patient[0].name.lastName}
                                        </p>
                                        <p>
                                          <strong>Test Name: </strong>
                                          {item.test_info.display_test_name}
                                        </p>
                                        <p>
                                          <strong>Institute Name: </strong>{" "}
                                          {item.institute_name}
                                        </p>
                                        <p>
                                          <strong>Doctor Name:</strong>{" "}
                                          {item.doctor_info.doctorName.label}
                                        </p>
                                        <p>
                                          <strong>Status:</strong>{" "}
                                          {item.status.status}
                                        </p>
                                        <p className="text-right mb-0">
                                          <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={item.report[0].location}
                                            download
                                          >
                                            <i class="fa fa-download reportDownload"></i>
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </PageWrap>
      {scrollTopBtn && <ScrollToTop />}
    </div>
  );
}

const mapStateToProps = (state) => ({
  getDoctorDetailState: state.getDoctorDetail,
});

const mapDispatchToProps = (dispatch) => ({
  getDoctorDetail: (params) => dispatch(getDoctorDetail(params)),
  getDoctorDetailReset: () => dispatch(getDoctorDetailReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DoctorDetail);
