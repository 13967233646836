import styled, { css } from "styled-components";

export const XImg = styled.img`
  width: 265px;
  height: 243px;
  border-radius: 30px;
  @media (max-width: 768px) {
    width: 132px;
    height: 198px;
  }
`;
export const DocDetailsHeading = styled.h1`
  margin: 0;
  font-size: 28px;
  line-height: 60px;
  font-family: Poppins;
  color: #1f274a;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
export const DocDetailsHeading4 = styled.h4`
  position: relative;
  width: 100%;
  font-size: 32px;
  line-height: 60px;
  font-weight: 600;
  font-family: Poppins;
  color: #1f274a;
`;
export const DocDetailsPara = styled.p`
  position: relative;
  width: 100%;
  font-size: 18px;
  line-height: 27px;
  font-family: Poppins;
  color: #a3a9bf;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    font-size: 14px;
    text-align: left;
    margin-bottom: 0px;
    margin-top: 5px;
  }
`;
export const DocDetailsParaFees = styled.p`
  margin-bottom: -7px !important;
  margin-top: 14px !important;
  position: relative;
  width: 100%;
  font-size: 18px;
  line-height: 27px;
  font-family: Poppins;
  color: #000000;
  @media (max-width: 768px) {
    font-size: 14px;
    text-align: left;
    margin-bottom: 0px;
    margin-top: 5px;
  }
`;
export const StarDiv = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin-top: 10px;
    float: left;
    box-shadow: 1px 4px 12px #ede8ed;
    border-radius: 8px;
  }
`;

export const StarImg = styled.img`
  display: none;
  @media (max-width: 768px) {
    display: block;
    width: 50px;
    height: 50px;
  }
`;
export const DescriptionDiv = styled.div`
  position: relative;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const DescriptionDivMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    text-align: left;
    width: 100%;
    height: 100%;
  }
`;
