import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { propTypes } from "react-bootstrap/esm/Image";
import { Link } from "react-router-dom";
import { InsightsboxHeading, YourHelthBg } from "../../page/Home/HomeStyle";
import { YourHelthImg3 } from "../HealthBlock/HealthBlockStyle";
import {
  AtEasyboxFlex,
  AtEasyboxTiles,
  AtEasyColumn,
  XImg,
} from "./ServicesStyle";
import "./Services.css";

function LilacInsightsServices(props) {
  const { serviceList } = props;

  return (
    <>
      <YourHelthBg>
        <div className="atEasySpace">
          <div className="container">
            <h1 className="heading-home1 text-center top-space">
              Services at Lilac Insights
            </h1>
            <div className="row">
              {serviceList.map((item, index) => {
                return (
                  <>
                    <AtEasyColumn
                      style={{
                        //height: "300px", // Fixed height for the card
                      }}
                      key={index}
                      className="col-12 col-md-6 col-lg-3  mb-4 text-center"
                    >
                      <Link
                        style={{ textDecoration: "none" }}
                        to={{
                          pathname: "/article/" + item.id,
                          state: {
                            id: item.id,
                          },
                        }}
                      >
                        <div className="atEasyHoverImg">
                          <div className="row align-items-center">
                            <div className="col-3 d-flex justify-content-center">
                              <img
                                style={{
                                  // maxHeight: "40%", // Adjusted to fit better
                                  // maxWidth: "40%", // Adjusted to fit better
                                  objectFit: "contain",
                                  filter:
                                    "invert(29%) sepia(60%) saturate(5356%) hue-rotate(270deg) brightness(95%) contrast(87%)",
                                }}
                                class="img-fluid"
                                src={item.imageIcon}
                                alt="no"
                              />
                            </div>

                            <div className="col-9 d-flex justify-content-center">
                              <InsightsboxHeading>
                                {item.title}
                              </InsightsboxHeading>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </AtEasyColumn>
                  </>
                );
              })}
            </div>
          </div>
        </div>
        {/* </div> */}
      </YourHelthBg>
    </>
  );
}

export default LilacInsightsServices;
