import { React, useState, useEffect } from "react";
import config from "../../config";
import {
  getAppointmentDetails,
  getAppointmentDetailsReset,
} from "../../action/getAppointmentDetailsAction";
import { connect } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import { getPatientToken } from "../../utils";
import {
  DrDetailsRightHead,
  TopContainer,
  PageWrap,
} from "./BookingDetailsStyle";
import { DoctorDetailBlock } from "../../component/DoctorDetailBlock/DoctorDetailBlock";
import moment from "moment";
import { CloseCircleOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Typography, Button, Modal, Tabs, Rate, Input } from "antd";

export const BookingDetails = (props) => {
  const {
    getAppointmentDetails,
    getAppointmentReset,
    getAppointmentDetailsState,
  } = props;
  const location = useLocation();
  const [appointmentData, setAppointmentData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalRVisible, setIsModalRVisible] = useState(false);
  const [isModal2Visible, setIsModal2Visible] = useState(false);
  const { TabPane } = Tabs;
  const [firstTabData, setFirstTabData] = useState([]);
  const [secondTabData, setSecondTabData] = useState([]);
  const [linkTabData, setLinkTabData] = useState([]);
  const [rateValue, setRateValue] = useState();
  const [reviw, setReviw] = useState();
  const [doctorData, setDoctorData] = useState({
    id: "",
    speciality: "",
    fname: "",
    lname: "",
    qualification: "",
    profile_picture: "",
    description: "",
    tier_1_fee: "",
    short_description: "",
    tier_2_fee: "",
    self_fee: "",
    couple_fee: "",
    family_fee: "",
    medicine_fee: "",
    exprience: "",
    salutation: "",
    clinic_address_line1: "",
    clinic_address_line2: "",
    clinic_city: "",
    clinic_country: "",
    clinic_postal_code: "",
    clinic_state: "",
    country: "",
    mobile_number: "",
  });
  const handleOnChange = (e) => {
    setReviw(e.target.value);
  };
  const reviews = () => {
    axios({
      url: `${config.api.base_url}testimonial/add`,
      method: "post",
      data: {
        _id: appointmentData.data.result._id,
        patient_id: appointmentData.data.result.patient_id._id,
        rating: rateValue,
        comment: reviw,
        doctor_id: appointmentData.data.result.doctor_id._id,
        book_appointment_id: appointmentData.data.result._id,
        __v: null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getPatientToken()}`,
      },
    })
      .then((res) => {
        console.log("reviw done", res);
        Modal.success({
          content: (
            <p style={{ textAlign: "center" }}>
              {" "}
              <br />
              {res.data.message}
            </p>
          ),
        });
        setIsModalRVisible(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const showRModal = () => {
    //fetch(`${config.api.base_url}testimonial/view?testimonial_id=${appointmentData.data.result._id}`,
    //    {
    //        headers: {
    //            "Content-Type": "application/json",
    //            "Authorization": `Bearer ${getPatientToken()}`
    //        }
    //    },
    //).then((result) => {
    //    result.json().then((resp) => {
    //        console.log("resp.data", resp.data)
    //    })
    //});
    setIsModalRVisible(true);
  };
  // const showModal2 = () => {
  //     setIsModal2Visible(true);
  // }
  const handleROk = () => {
    setIsModalRVisible(false);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const getAppointmentDetailsApi = async (id) => {
    const response = await axios({
      url: `${config.api.base_url}book-appointment/` + id,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getPatientToken()}`,
      },
    });
    setAppointmentData(response.data);

    setDoctorData({
      ["id"]: response.data.data.result._id,
      ["speciality"]: response.data.data.result.doctor_id.speciality,
      ["fname"]: response.data.data.result.doctor_id.fname,
      ["lname"]: response.data.data.result.doctor_id.lname,
      ["qualification"]: response.data.data.result.doctor_id.qualification,
      ["profile_picture"]: response.data.data.result.doctor_id.profile_picture,
      ["description"]: response.data.data.result.doctor_id.description,
      ["tier_1_fee"]: response.data.data.result.doctor_id.tier_1_fee,
      ["tier_2_fee"]: response.data.data.result.doctor_id.tier_2_fee,
      ["self_fee"]: response.data.data.result.doctor_id.self_fee,
      ["couple_fee"]: response.data.data.result.doctor_id.couple_fee,
      ["family_fee"]: response.data.data.result.doctor_id.family_fee,
      ["medicine_fee"]: response.data.data.result.doctor_id.medicine_fee,
      ["short_description"]:
        response.data.data.result.doctor_id.short_description,
      ["exprience"]: response.data.data.result.doctor_id.exprience,
      ["salutation"]:
        response.data.data.result.doctor_id.salutation != null
          ? response.data.data.result.doctor_id.salutation.value
          : "",
      ["clinic_address_line1"]:
        response.data.data.result.doctor_id.clinic_address_line1,
      ["clinic_address_line2"]:
        response.data.data.result.doctor_id.clinic_address_line2,
      ["clinic_city"]: response.data.data.result.doctor_id.clinic_city,
      ["clinic_country"]: response.data.data.result.doctor_id.clinic_country,
      ["clinic_postal_code"]:
        response.data.data.result.doctor_id.clinic_postal_code,
      ["clinic_state"]: response.data.data.result.doctor_id.clinic_state,
      ["country"]: response.data.data.result.doctor_id.country,
      ["mobile_number"]: response.data.data.result.doctor_id.mobile_number,
      ["language"]: response.data.data.result.doctor_id.language,
    });
    firstTab(response.data.data.result._id);
    secondTab();
    // axios.get(
    //     `${config.api.base_url}report/list`,
    //     {
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${getPatientToken()}`
    //         }
    //     }).then((res) => {
    //         console.log("List of reports", res)
    //         // response.data.data.result._id

    //     }).catch((e) => {
    //         console.log(e)
    //     })
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAppointmentDetailsApi(location.state.Details.item._id);
  }, []);

  let QuaValues = [];
  let LangValues = [];
  const date = new Date(doctorData.exprience).toLocaleDateString("us");
  if (doctorData.speciality) {
    for (let i = 0; i < doctorData.speciality.length; i++) {
      QuaValues[i] = " " + doctorData.speciality[i].value + " ";
    }
  }
  if (doctorData.speciality) {
    for (let i = 0; i < doctorData.language.length; i++) {
      LangValues[i] = " " + doctorData.language[i].value + " ";
    }
  }
  function getSpeciality(spec) {
    let QuaValues = [];
    for (let i = 0; i < spec.length; i++) {
      QuaValues[i] = spec[i].value;
    }
    return QuaValues.join(" , ");
  }

  // function cancelBooking(e) {
  //     Modal.confirm({
  //         title: 'Confirm',
  //         content: (
  //             <div><h3>Confirm Cancel</h3></div>
  //         ),
  //         okText: 'Confirm',
  //         onOk() {
  //             const bookingParams = {
  //                 "_id": appointmentData.data.result.id
  //             }
  //             axios({
  //                 url: `${config.api.base_url}/book-appointment/cancel-book-appointment`,
  //                 method: "post",
  //                 data: bookingParams,
  //                 headers:{
  //                     "Content-Type": "application/json",
  //                     "Authorization": `Bearer ${getPatientToken()}`
  //                 }
  //             })
  //             .then(() => {})
  //             if("error") {
  //                 alert("Error in cancelling booking");
  //             }
  //             else {
  //                 showModal2();
  //             }
  //         }
  //     });
  // }

  //  useEffect(()=>{
  //     if(getAppointmentDetailsState.apiState=="success"){
  //         console.log("Booking Details"+getAppointmentDetailsState.data.data)
  //     }
  //  },[getAppointmentDetailsState])
  function cancelAppointment() {
    Modal.confirm({
      content: "Are you sure you want to Cancel?",
      onOk: () => {
        axios({
          url: `${config.api.base_url}book-appointment/cancel-book-appointment`,
          method: "post",
          data: { book_appointment_id: doctorData.id },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getPatientToken()}`,
          },
        })
          .then((res) => {
            console.log("response after deleting", res);
            window.location.replace("/consultation");
          })
          .catch((e) => {
            console.log(e);
          });
      },
    });
  }

  function getTabKey(key) {
    //console.log(key);
    if (key == 1) {
      firstTab();
    }
    if (key == 2) {
      secondTab();
    }
  }

  function firstTab(id) {
    //const firstTab = () => {
    fetch(`${config.api.base_url}report/list`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getPatientToken()}`,
      },
    }).then((result) => {
      result.json().then((resp) => {
        console.log("resp.data", id);
        var currentBookingReports = resp.data.result.filter((e) =>
          e.book_appointment_id.includes(id)
        );
        console.log("currentBookingReport", resp.data.result);
        setFirstTabData(resp.data.result);
        //console.log(resp)
        //console.log(resp.data.result[0].report[0].location)
        //console.log(resp.data.result[0].file_path)
        //console.log(firstTabData.result[0].file_path)
      });
    });
  }

  function secondTab() {
    fetch(`${config.api.base_url}report/get-test-reports`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getPatientToken()}`,
      },
    }).then((result) => {
      result.json().then((resp) => {
        setSecondTabData(resp.data);
        setLinkTabData(resp.data.result[0]?.report[0]?.location);
        //console.log(resp.data.result.report)

        //console.log(resp.data.result[0].report[0].location)
      });
    });
  }

  // console.log("appointmnet is can cancel",appointmentData.data.result._id )

  return (
    <div className="container pl-sm-5 pl-md-5 pr-sm-5 pr-md-5 ">
      <TopContainer>
        <img
          className="align-center mobile-height"
          src="./images/lilac-insights-logo.svg"
          alt="exp"
        />
        {/* <Title level={1}>Consult</Title> */}
      </TopContainer>
      <img
        alt="right-bg"
        className="doctorRightBg1 hideMobile"
        // style={{ top: "40%" }}
        src="./images/svg/doctorbgRight.svg"
      />
      <PageWrap>
        <div className="row">
          <div className="col-sm-12">
            <h1 className="dtDetailsHead">Appointment Details </h1>
          </div>
        </div>
        {appointmentData != undefined ? (
          <div className="row">
            <div className="row">
              <div className="col-sm-12 col-md-8 col-lg-8 col-12 mb-4 first">
                <DoctorDetailBlock
                  doctorData={doctorData}
                  isExperience={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-12 mb-4">
                <div className="drDetailsRightBoxWrap ht-100">
                  <div className="drDetailsRightBox2">
                    <div className="drlistImgRightWrapLeft">
                      <img
                        className="imp-fluid"
                        src="./images/Qdetails.svg"
                        alt=""
                      />
                    </div>
                    <div className="drlistImgRightWrapRight">
                      <DrDetailsRightHead>
                        Qualification Details
                      </DrDetailsRightHead>
                      <h6 className="drDetailsRightHead2 drDetailsInfo">
                        {doctorData.qualification}
                      </h6>
                    </div>
                    <div className="clearfix"></div>
                  </div>

                  <div className="drDetailsRightBox2">
                    <div className="drlistImgRightWrapLeft">
                      <img
                        className="imp-fluid"
                        src="./images/specialization.svg"
                        alt=""
                      />
                    </div>
                    <div className="drlistImgRightWrapRight">
                      <DrDetailsRightHead>Specialisations</DrDetailsRightHead>
                      <h6 className="drDetailsRightHead2 drDetailsInfo">
                        {QuaValues.toString()}
                      </h6>
                    </div>
                    <div className="clearfix"></div>
                  </div>

                  {/* {isBooking?  */}
                  <div className="drDetailsRightBox2">
                    <div className="drlistImgRightWrapLeft">
                      <svg
                        className="imp-fluid"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM18.92 8H15.97C15.65 6.75 15.19 5.55 14.59 4.44C16.43 5.07 17.96 6.35 18.92 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4.26 14C4.1 13.36 4 12.69 4 12C4 11.31 4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12C7.5 12.68 7.56 13.34 7.64 14H4.26ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM8.03 8H5.08C6.04 6.34 7.57 5.07 9.41 4.44C8.81 5.55 8.35 6.75 8.03 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM14.34 14H9.66C9.57 13.34 9.5 12.68 9.5 12C9.5 11.32 9.57 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12C14.5 12.68 14.43 13.34 14.34 14ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.36 14C16.44 13.34 16.5 12.68 16.5 12C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12C20 12.69 19.9 13.36 19.74 14H16.36Z"
                          fill="url(#paint0_linear_3850:4296)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_3850:4296"
                            x1="2"
                            y1="2"
                            x2="23.4926"
                            y2="3.75865"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#D487D4" />
                            <stop offset="1" stopColor="#A54EA5" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div className="drlistImgRightWrapRight">
                      <DrDetailsRightHead>Languages</DrDetailsRightHead>
                      <h6 className="drDetailsRightHead2 drDetailsInfo">
                        {LangValues.toString()}
                      </h6>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-12 mb-4">
                <div className="drDetailsRightBoxWrap ht-100">
                  <div className="drDetailsRightBox2">
                    {/* <div>
                      <h3 className="text-center">Booking Details</h3>
                    </div> */}
                    <div className="drlistImgRightWrapLeft">
                      <img
                        className="imp-fluid"
                        src="./images/booked_for.svg"
                        alt=""
                      />
                    </div>
                    <div className="drlistImgRightWrapRight">
                      <DrDetailsRightHead>Booked For</DrDetailsRightHead>
                      <h6 className="drDetailsRightHead2 drDetailsInfo">
                        {appointmentData.data.result.patient_id.name.firstName}
                      </h6>
                    </div>
                    <div className="clearfix"></div>

                    <div className="drlistImgRightWrapLeft">
                      <img
                        className="imp-fluid"
                        src="./images/message_icon.svg"
                        alt=""
                      />
                    </div>
                    <div className="drlistImgRightWrapRight">
                      <DrDetailsRightHead>Consultation Type</DrDetailsRightHead>
                      <h6 className="drDetailsRightHead2 drDetailsInfo">
                        Video
                      </h6>
                    </div>
                    <div className="clearfix"></div>

                    <div className="drlistImgRightWrapLeft">
                      <img
                        className="imp-fluid"
                        src="./images/Consultation.svg"
                        alt=""
                      />
                    </div>
                    <div className="drlistImgRightWrapRight">
                      <DrDetailsRightHead>Consultation Date</DrDetailsRightHead>
                      <h6 className="drDetailsRightHead2 drDetailsInfo">
                        {new Date(
                          appointmentData.data.result.date
                        ).getUTCDate() +
                          " " +
                          new Date(
                            appointmentData.data.result.date
                          ).toLocaleString("default", { month: "short" }) +
                          " " +
                          new Date(
                            appointmentData.data.result.date
                          ).getFullYear()}
                      </h6>
                    </div>
                    <div className="clearfix"></div>

                    <div className="drlistImgRightWrapLeft">
                      <img
                        className="imp-fluid"
                        src="./images/Chistory.svg"
                        alt=""
                      />
                    </div>
                    <div className="drlistImgRightWrapRight">
                      <DrDetailsRightHead>Total time</DrDetailsRightHead>
                      <h6 className="drDetailsRightHead2 drDetailsInfo">
                        {moment(
                          appointmentData.data.result.time_slot_start,
                          "HH:mm:ss"
                        ).format("hh:mm A")}{" "}
                        -{" "}
                        {moment(
                          appointmentData.data.result.time_slot_end,
                          "HH:mm"
                        ).format("hh:mm A")}
                      </h6>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              {/* <div className="drDetailsRightBoxWrap">
                                <div className='drDetailsRightBox2 mb-0'>
                                    <div className='drlistImgRightWrapLeft'>
                                        <img className="imp-fluid" src="./images/report_id.svg" alt="" />
                                    </div>
                                    <div className='drlistImgRightWrapRight'>
                                        <DrDetailsRightHead>
                                            <span className='btn p-0'  >
                                                <h5 className='drDetailsRightHead text-center'>
                                                    Order Details  </h5></span>


                                        </DrDetailsRightHead>
                                    </div>
                                    <div className='clearfix'></div>
                                </div>
                            </div> */}
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-12 mb-4">
                <Link
                  to={`/appointment/meeting/${appointmentData.data.result._id}`}
                >
                  {location.state.app != "past" && (
                    <Button
                      className="themeBtn w-100 mb-4"
                      size="large"
                      style={{ border: "1px solid #CCC" }}
                      disabled={!appointmentData.data.result.is_can_join_room}
                    >
                      Join
                    </Button>
                  )}
                </Link>
                <div className="drDetailsRightBoxWrap2">
                  <div className="drDetailsRightBox2 mb-0">
                    <div className="drlistImgRightWrapLeft">
                      <img
                        className="imp-fluid"
                        src="./images/share_report.svg"
                        alt=""
                      />
                    </div>
                    <div
                      className="drlistImgRightWrapRight"
                      onClick={showModal}
                    >
                      <DrDetailsRightHead>
                        <span className="btn p-0">
                          <h5 className="drDetailsRightHead text-center">
                            Shared Reports
                          </h5>
                        </span>
                      </DrDetailsRightHead>
                    </div>
                    <Modal
                      visible={isModalVisible}
                      onCancel={handleOk}
                      width={1000}
                      footer={null}
                    >
                      <div className="row">
                        <div className="offset-sm-1 col-sm-10 col-12">
                          <div className="row">
                            <div className="col-sm-12 col-12">
                              <Tabs
                                defaultActiveKey="1"
                                // onChange={getTabKey}
                                tabPosition="top"
                                className="reportTabWrap"
                              >
                                <TabPane tab="Patient Reports" key="1">
                                  <div className="row">
                                    {appointmentData.data.result &&
                                    appointmentData.data.result.report.length >
                                      0 ? (
                                      <>
                                        {appointmentData.data.result.report.map(
                                          (item) => (
                                            <>
                                              {console.log(item)}
                                              {item.report_of === "patient" && (
                                                <div className="col-sm-6 col-12 mb-3">
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-around",
                                                      border:
                                                        "1px solid #d9d9d9",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    {item.file_path.split(
                                                      "."
                                                    )[1] === "pdf" ? (
                                                      <FilePdfOutlined
                                                        style={{
                                                          marginRight: "5px",
                                                          fontSize: "24px",
                                                        }}
                                                      />
                                                    ) : (
                                                      <img
                                                        src={
                                                          `${config.api.s3_url}patient-report/` +
                                                          item.file_path
                                                        }
                                                        alt="report"
                                                        style={{
                                                          height: "24px",
                                                          width: "24px",
                                                          marginRight: "5px",
                                                        }}
                                                      />
                                                    )}
                                                    <p>
                                                      <span
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                      >
                                                        {item.title}
                                                      </span>
                                                    </p>
                                                    <p className="text-right mb-0">
                                                      <a
                                                        target="_blank"
                                                        href={
                                                          `${config.api.s3_url}patient-report/` +
                                                          item.file_path
                                                        }
                                                        download
                                                      >
                                                        <i className="fa fa-download reportDownload"></i>
                                                      </a>
                                                    </p>
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          )
                                        )}
                                      </>
                                    ) : (
                                      <div>
                                        <h4>You don't have any reports</h4>
                                      </div>
                                    )}
                                  </div>
                                </TabPane>

                                <TabPane tab="Doctor Reports" key="2">
                                  <div className="row">
                                    {appointmentData.data.result &&
                                    appointmentData.data.result.report.length >
                                      0 ? (
                                      <>
                                        {appointmentData.data.result.report.map(
                                          (item) => (
                                            <>
                                              {item.report_of === "doctor" && (
                                                <div className="col-sm-6 col-12 mb-3">
                                                  <div className="reportBox">
                                                    <div className="row align-items-center">
                                                      <div className="col-4 text-center">
                                                        {/*
                                                        <img className="img-fluid" style={{height:'240px'}} src={item.report[0].location} />
                                                        */}
                                                        <i
                                                          style={{
                                                            fontSize: "8rem",
                                                            color: "#CCC",
                                                          }}
                                                          class="fa fa-files-o"
                                                          aria-hidden="true"
                                                        ></i>
                                                      </div>
                                                      <div className="col-8">
                                                        <p className="text-right mb-0">
                                                          <a
                                                            target="_blank"
                                                            href={
                                                              `${config.api.s3_url}doctor-report/` +
                                                              item.file_path
                                                            }
                                                            download
                                                          >
                                                            {item.title}
                                                            <i class="fa fa-download reportDownload"></i>
                                                          </a>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          )
                                        )}
                                      </>
                                    ) : (
                                      // <div className="col-sm-12 col-12 text-center mb-5">
                                      <h4>You don't have any reports</h4>
                                      // </div>
                                    )}
                                  </div>
                                </TabPane>
                              </Tabs>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    <div className="clearfix"></div>
                  </div>
                </div>
                <div className="clearfix"></div>
                {location.state.app == "past" && (
                  <div className="drDetailsRightBoxWrap2">
                    <div className="drDetailsRightBox2 mb-0">
                      <div className="drlistImgRightWrapLeft">
                        <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.1943 7.47712L13.591 10.0023C13.4363 10.1522 13.3016 10.565 13.3369 10.7776L13.9412 14.3541C14.0248 14.8495 13.9128 15.2944 13.6257 15.6075C13.6147 15.6204 13.3142 16 12.5949 16C12.3776 16 12.1582 15.9153 11.9404 15.8L8.7338 14.1044C8.68171 14.0768 8.57489 14.0434 8.42967 14.0315C8.20498 14.0137 8.00714 14.0557 7.91821 14.1021L4.70375 15.7819C4.41119 15.9345 4.12021 15.9679 3.83713 15.9782C3.23044 16.0003 2.98681 15.5451 2.95787 15.508C2.78844 15.2902 2.60848 14.9084 2.70978 14.3257L3.33225 10.7524C3.36882 10.54 3.23596 10.1265 3.08231 9.9765L0.491118 7.43792C0.0575419 7.01303 -0.098998 6.48816 0.0617514 5.99776C0.222238 5.50814 0.659234 5.1777 1.25987 5.09193L4.85082 4.57969C5.06366 4.54891 5.41621 4.29476 5.51171 4.10139L7.12499 0.853511C7.4115 0.277602 7.90822 -0.0396873 8.45492 0.00398602C8.93138 0.0416082 9.34602 0.353898 9.59411 0.859825L11.1911 4.11638C11.2858 4.30975 11.6368 4.56574 11.8496 4.59731L15.4382 5.12797C16.0386 5.2169 16.4734 5.54918 16.6316 6.04011C16.7894 6.53052 16.6305 7.05486 16.1943 7.47712Z"
                            fill="#F6C143"
                          />
                        </svg>
                      </div>
                      <div
                        className="drlistImgRightWrapRight"
                        onClick={showRModal}
                      >
                        <DrDetailsRightHead>
                          <span className="btn p-0">
                            <h5 className="drDetailsRightHead text-center">
                              Add Review
                            </h5>
                          </span>
                        </DrDetailsRightHead>
                      </div>

                      <Modal
                        visible={isModalRVisible}
                        onCancel={handleROk}
                        width={600}
                        footer={null}
                      >
                        <div className="row">
                          <div className="offset-sm-1 col-sm-10 col-12">
                            <div className="row">
                              <div className="col-sm-12 col-12 ">
                                <h2 className="d-flex justify-content-center">
                                  {" "}
                                  Feedback
                                </h2>
                                <span className="d-flex justify-content-center">
                                  <Rate
                                    style={{ textAlign: "center" }}
                                    onChange={setRateValue}
                                    value={rateValue}
                                  />
                                </span>
                                <Input.TextArea
                                  rows={3}
                                  style={{ height: 150 }}
                                  onChange={handleOnChange}
                                />
                                <Button
                                  className="themeBtn w-100 mt-4"
                                  size="large"
                                  onClick={reviews}
                                >
                                  Submit
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>

                      <div className="clearfix"></div>
                    </div>
                  </div>
                )}
                {location.state.app == "past" ? (
                  <div>
                    <Button className="themeBtn w-100 mb-4" size="large">
                      <Link
                        to={{
                          pathname: "/doctor-detail",
                          state: {
                            doctor: appointmentData.data.result.doctor_id._id,
                            type: "",
                            isBooking: false,
                          },
                        }}
                        className="invisiLink"
                      >
                        Book
                      </Link>
                    </Button>
                  </div>
                ) : (
                  <div className="drDetailsRightBoxWrap2">
                    <div className="drDetailsRightBox2 mb-0 ">
                      <div className="drlistImgRightWrapLeft">
                        <CloseCircleOutlined
                          style={{ fontSize: "20px", color: "#a54ea5" }}
                        />
                      </div>
                      <button
                        className="btn p-0"
                        onClick={cancelAppointment}
                        disabled={
                          !appointmentData.data.result.is_can_cancel_appointment
                        }
                      >
                        <h5 className="drDetailsRightHead text-center m-left">
                          Cancel Booking
                        </h5>
                      </button>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="loader-container">Loading...</div>
        )}
      </PageWrap>
    </div>
  );
};
const mapStateToProps = (state) => ({
  getAppointmentDetailsState: state.getAppointmentDetails,
});
const mapDispatchToProps = (dispatch) => ({
  getAppointmentDetails: (params) => dispatch(getAppointmentDetails(params)),
  getAppointmentDetailsReset: () => dispatch(getAppointmentDetailsReset()),
});
export default connect(mapStateToProps, mapDispatchToProps)(BookingDetails);
