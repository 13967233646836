import React from "react";
import { connect } from "react-redux";
import {
  XImg,
  DocDetailsPara,
  DocDetailsHeading4,
  DocDetailsHeading,
  StarDiv,
  StarImg,
  DescriptionDiv,
  DescriptionDivMobile,
  DocDetailsParaFees,
} from "./DoctorDetailBlockStyle";
import moment from "moment";
import config from "../../config";

export const DoctorDetailBlock = (props) => {
  let QuaValues = [];
  const date = props.doctorData.exprience;
  if(props.doctorData.speciality)
  {
    props.doctorData.speciality.forEach((element,index) => {
      QuaValues[index] = element?.value;  
    });
  }
  // for (let i = 0; i < props.doctorData.speciality.length; i++) {
  //   QuaValues[i] = props.doctorData.speciality[i].value;
  // }
  // const { gender } = props.doctorData;

  function getExperience(date) {
    if (date) {
      const expDate = moment(date);
      const experience = moment().diff(expDate, "years");
      return experience; 
    } else {
      return "0";
    }
  }
  
  console.log(props);
  return (
    <div>
      <div className="row">
        <div className="col-sm-3 col-12">
          <img
            style={{
              borderRadius: "15px",
              // maxHeight: "200px",
            }}
            className="img-fluid mb-3 mb-sm-0"
            src={
              props.doctorData.profile_picture ? 
                `${config.api.s3_url}doctor/${props.doctorData.profile_picture}`
              :
              props.doctorData.gender?.toLowerCase() === "male" ?
                `/images/avatar_male.svg`
              :
              props.doctorData.gender?.toLowerCase() === "female" ?
                `/images/avatar_female.svg`
              :
              `/images/avatar_transgender.svg`
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              switch(props.doctorData.gender?.toLowerCase())
              {
                case "male":
                  currentTarget.src = "/images/avatar_male.svg";
                  break;
                case "female":
                  currentTarget.src = "/images/avatar_female.svg";
                  break;
                default: 
                  currentTarget.src = "/images/avatar_transgender.svg";
              }
            }}
            alt="doc-img"
          />
        </div>
        <div className="col-sm-9 col-12">
          <div className="cardData ml-0">
            <DocDetailsHeading>
              {props.doctorData.salutation} {props.doctorData.fname}{" "}
              {props.doctorData.lname}{" "}
            </DocDetailsHeading>
            <DocDetailsPara>{QuaValues.join(", ")}</DocDetailsPara>
            {props.isExperience ? (
              <>
                <StarDiv className="mr-1">
                  <StarImg
                    className="align-center"
                    src="./images/DocExpStar.svg"
                    alt="exp"
                  />
                </StarDiv>
                <DocDetailsPara className="ml-1" style={{ marginTop: "20px" }}>
                  Experience: <b>{getExperience(date)} Years</b>
                </DocDetailsPara>
                <DocDetailsHeading4 className="drDetailsHeading3"></DocDetailsHeading4>
              </>
            ) : (
              <div>
                <StarDiv className="mr-3">
                  <StarImg
                    className="align-center"
                    src="./images/DocExpStar.svg"
                    alt="exp"
                  />
                </StarDiv>
                <div>
                  <DocDetailsParaFees>Fees</DocDetailsParaFees>
                  <DocDetailsHeading4>
                    <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                    {props.doctorData.tier_1_fee}
                  </DocDetailsHeading4>
                </div>
              </div>
            )}
            <div style={{ clear: "both" }}></div>
            <DescriptionDiv>
              <hr />
              <DocDetailsPara>
                {props.doctorData.short_description}
              </DocDetailsPara>
            </DescriptionDiv>
          </div>
          <DescriptionDivMobile className="mt-2">
            <DocDetailsPara>
              {props.doctorData.short_description}
            </DocDetailsPara>
          </DescriptionDivMobile>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorDetailBlock);
