import styled from 'styled-components';

export const Styled = styled.div`
label.ant-checkbox-wrapper {


      > span:first-child {
         width: auto;
        height: max-content;
        border-radius: 40px;
        box-shadow: 1px 6px 22px #EDE8ED;
        border: none;
display:none;
      }
      > span:last-child {
          width: auto;
        height: max-content;
        border-radius: 40px;
        box-shadow: 1px 6px 22px #EDE8ED;
        border: none;
      }

  

  }
  
label.ant-checkbox-wrapper-checked {
 border-radius: 40px;
  background:#cfb8d3;
border:none

    }
    
`;
export const SuccessModal = styled.div`
      h6{
        margin-bottom:30px;
      }
      p{
        display:flex;
      }
      p span{
        margin-left:auto;
      }
`
export const BookingHeader = styled.div`
box-sizing: border-box;



background: #FFFFFF;
border: 1px solid #E8E8E8;
box-shadow: 0px 4px 30px rgba(202, 212, 226, 0.3);
border-radius: 20px;
padding: 30px;

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 24px;

/* identical to box height */


color: #1F274A;
@media (max-width: 768px) {
background: #FFFFFF;
border: 1px solid #E8E8E8;
box-shadow: 0px 4px 30px rgba(202, 212, 226, 0.3);
border-radius: 20px;
padding: 30px;

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;

/* identical to box height */


color: #1F274A;
 

}

`
export const ContainsBooking = styled.div` 
box-sizing: border-box;
left: 140px;
margin-top:20px;
padding:20px;
background: #FFFFFF;
border: 1px solid #E8E8E8;
box-shadow: 0px 4px 30px rgba(202, 212, 226, 0.3);
border-radius: 20px;
`

