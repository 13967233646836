import React from "react";
import { Row, Col, Typography, Space, Divider } from "antd";

//STYLE
import {
  FootContainer,
  XCol,
  YCol,
  PCol,
  QCol,
  RCol,
  SCol,
  Xlink,
  Xdiv,
  XSpace,
  XFoot,
  Image,
  XButton,
  BtnTxt,
  XTitle,
  FooterBtn,
  AppleStoreImg,
  GoogleStoreImg,
  SocialButton,
} from "./FooterStyle";
import {
  PhoneFilled,
  MailOutlined,
  PushpinOutlined,
  YoutubeFilled,
  LinkedinFilled,
  TwitterCircleFilled,
  FacebookFilled,
  InstagramFilled,
  MailFilled,
} from "@ant-design/icons";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

export const Footer = (props) => {
  const { Title, Text } = Typography;

  return (
    // <div>
      <FootContainer className="footerWrap">
        <div className="container">
          <div className="row pt-5">
            <div className="col-sm-4 col-12">
              <div>
                <Image
                  src={
                    process.env.PUBLIC_URL + "/images/lilac-insights-logo (1) 1.svg"
                  }
                  alt="no"
                />
              </div>
              <h1 level={3} className="compy">
                Lilac Insights Pvt. Ltd.
              </h1>
              <p className="footerPara1">
                301-302 Rupa Solitaire Premises Co-Op Society Ltd., Sector-1,
                Building A -1, Millennium Business Park, MIDC. Navi Mumbai
                400710, Maharashtra, India
              </p>
            </div>
            <div className="col-sm-3 col-12">
              <h1 level={3} className="footerHead2">
                Quick Links
              </h1>
              <ul className="footerLinks">
                {/* <li>
                  <Xlink to="">Home</Xlink>
                </li> */}
                <li>
                  <Xlink
                    to={{
                      pathname:
                        "https://www.lilacinsights.com/about-us/what-we-do.php",
                    }}
                    target="_blank"
                  >
                    About Us
                  </Xlink>
                </li>
                {/* <li>
                  <Xlink
                    to={{
                      pathname:
                        "https://www.lilacinsights.com/about-us/our-journey.php",
                    }}
                    target="_blank"
                  >
                    Our Journey
                  </Xlink>
                </li>
                <li>
                  <Xlink
                    to={{
                      pathname:
                        "https://www.lilacinsights.com/about-us/our-culture.php",
                    }}
                    target="_blank"
                  >
                    Our Culture
                  </Xlink>
                </li>
                <li>
                  <Xlink
                    to={{
                      pathname:
                        "https://www.lilacinsights.com/news-media/news.php",
                    }}
                    target="_blank"
                  >
                    In the News
                  </Xlink>
                </li>
                <li>
                  <Xlink
                    to={{
                      pathname:
                        "https://www.lilacinsights.com/resources/events.php",
                    }}
                    target="_blank"
                  >
                    Events
                  </Xlink>
                </li>
                <li>
                  <Xlink
                    to={{
                      pathname: "https://www.lilacinsights.com/careers.php",
                    }}
                    target="_blank"
                  >
                    Careers
                  </Xlink>
                </li> */}
                <li>
                  <Link to="/quick-support">Quick Support</Link>
                </li>
                <li>
                  <Xlink to="/ContactUs">Contact Us</Xlink>
                </li>
              </ul>
              <div className="mt-2">
                <p className="footerParaPhone">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  +91-22 4184 1438
                </p>
                <p className="footerParaPhone">
                  <i class="fa fa-envelope-o" aria-hidden="true"></i>
                  contact@lilacinsights.com
                </p>
              </div>
            </div>
            <div className="col-sm-2 col-12">
              <h1 level={3} className="footerHead2">
                Legal Policies
              </h1>
              <ul className="footerLinks">
                <li>
                  <Xlink
                    to={{
                      pathname:
                        "https://www.lilacinsights.com/privacy-policy.php",
                    }}
                    target="_blank"
                  >
                    Privacy Policy
                  </Xlink>
                </li>
                <li>
                  <Xlink
                    to={{
                      pathname:
                        "https://www.lilacinsights.com/privacy-policy.php",
                    }}
                    target="_blank"
                  >
                    Cookie Policy
                  </Xlink>
                </li>
              </ul>
            </div>
            <div className="col-sm-3 col-12">
              <h1 level={3} className="footerHead2">
                Connect With Us
              </h1>
              <div>
                <p className="footerSocialList">
                  <Link
                    to={{
                      pathname:
                        "https://www.youtube.com/channel/UCQpq6f2HZV1LOEsAay4UFCg",
                    }}
                    target="_blank"
                  >
                    <i
                      class="fa fa-youtube-play"
                      aria-hidden="true"
                      href="https://google.com"
                    ></i>
                  </Link>
                </p>
                <p className="footerSocialList">
                  <Link
                    to={{
                      pathname:
                        "https://www.linkedin.com/company/lilac-insights/",
                    }}
                    target="_blank"
                  >
                    <i class="fa fa-linkedin" aria-hidden="true"></i>
                  </Link>
                </p>
                <p className="footerSocialList">
                  <Link
                    to={{ pathname: "https://twitter.com/lilacinsights" }}
                    target="_blank"
                  >
                    <i class="fa fa-twitter" aria-hidden="true"></i>
                  </Link>
                </p>
                <p className="footerSocialList">
                  <Link
                    to={{ pathname: "https://www.facebook.com/LilacInsights/" }}
                    target="_blank"
                  >
                    <i class="fa fa-facebook-square" aria-hidden="true"></i>
                  </Link>
                </p>
                <p className="footerSocialList">
                  <Link
                    to={{
                      pathname: "https://www.instagram.com/lilacinsights/",
                    }}
                    target="_blank"
                  >
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                  </Link>
                </p>
                <p className="footerSocialList">
                  <Link to={{ pathname: "" }}>
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                  </Link>
                </p>
                <p className="footerSocialList">
                  <Link to={{ pathname: "" }}>
                    <i class="fa fa-phone" aria-hidden="true"></i>
                  </Link>
                </p>
              </div>
              <SocialButton className="socialButton">
                <Link
                  to={{
                    pathname:
                      "https://play.google.com/store/apps/details?id=com.lilacinsights.healthcare",
                  }}
                  target="_blank"
                >
                  <GoogleStoreImg
                    className="img-fluid svapp"
                    src="/images/GoogleStore.svg"
                    alt="Google play store"
                  />
                </Link>
                <Link
                  to={{ pathname: "https://apps.apple.com/in/app/" }}
                  target="_blank"
                >
                  <AppleStoreImg
                    className="img-fluid svapp"
                    src="/images/AppleStore.svg"
                    alt="Apple play store"
                  />
                </Link>
              </SocialButton>
            </div>
            {/* <div className="offset-sm-1 col-sm-5 col-12">
              <h1 level={3} className="footerHead1">
                Main Lab:
              </h1>
              <p className="footerParaPhone">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                Mumbai
              </p>
              <h1 level={3} className="footerHead1 mt-4">
                Our Satellite Centers:
              </h1>
              <p className="footerParaList">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                Bengaluru
              </p>
              <p className="footerParaList">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                Pune
              </p>
              <p className="footerParaList">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                Chandigarh
              </p>
              <p className="footerParaList">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                Kolkata
              </p>
            </div> */}
          </div>
          <hr className="divderHr" />
          <XFoot className="mt-3">
            Copyright Lilac Insights, All Rights Reserved.
          </XFoot>
          {/* <div className="row pt-3">
            {/* <div className="col-sm-3 col-12">
              <h1 level={3} className="footerHead2">
                Services
              </h1>
              <ul className="footerLinks">
                <li>
                  <Xlink
                    to={{
                      pathname:
                        "https://www.lilacinsights.com/services/reproductive-genetics-program/prenatal-screening.php#submenupage",
                    }}
                    target="_blank"
                  >
                    PNS
                  </Xlink>
                </li>
                <li>
                  <Xlink
                    to={{
                      pathname:
                        "https://www.lilacinsights.com/services/reproductive-genetics-program/non-invasive-prenatal-testing-nipt.php#submenupage",
                    }}
                    target="_blank"
                  >
                    NIPS/ NIPT
                  </Xlink>
                </li>
                <li>
                  <Xlink
                    to={{
                      pathname:
                        "https://www.lilacinsights.com/services/reproductive-genetics-program/blood-karyotying.php#submenupage",
                    }}
                    target="_blank"
                  >
                    Blood Karyotyping
                  </Xlink>
                </li>
                <li>
                  <Xlink
                    to={{
                      pathname:
                        "https://www.lilacinsights.com/services/reproductive-genetics-program/chromosomal-microarray.php#submenupage",
                    }}
                    target="_blank"
                  >
                    Chromosomal Microarray
                  </Xlink>
                </li>
                <li>
                  <Xlink
                    to={{
                      pathname:
                        "https://www.lilacinsights.com/services/reproductive-genetics-program/next-generation-sequencing.php#submenupage",
                    }}
                    target="_blank"
                  >
                    Next Generation Sequencing
                  </Xlink>
                </li>
                <li>
                  <Xlink
                    to={{
                      pathname:
                        "https://www.lilacinsights.com/services/cancer-genetics-program.php",
                    }}
                    target="_blank"
                  >
                    Cancer Genetics Program
                  </Xlink>
                </li>
                <li>
                  <Xlink
                    to={{
                      pathname:
                        "https://www.lilacinsights.com/services/sujanan-program/fetal-autopsy.php#submenupage",
                    }}
                    target="_blank"
                  >
                    Fetal Autopsy
                  </Xlink>
                </li>
                <li>
                  <Xlink
                    to={{
                      pathname:
                        "https://www.lilacinsights.com/services/sujanan-program/genetic-consultation-counseling-services.php#submenupage",
                    }}
                    target="_blank"
                  >
                    Genetic Consultation & Counseling Services
                  </Xlink>
                </li>
                <li>
                  <Xlink
                    to={{
                      pathname:
                        "https://www.lilacinsights.com/services/atease-mental-health-platform.php",
                    }}
                    target="_blank"
                  >
                    AtEase - Mental Health Platform
                  </Xlink>
                </li>
              </ul>
              <FooterBtn>
                <Link to="/consultation" className="invisiLink">
                  Request An Appointment
                </Link>
              </FooterBtn>
            </div> */}
          {/* </div> */}
        </div>

        <br />
      </FootContainer>
    // </div>
  );
};

export default Footer;
// atEaseFoot
